import { saveUserAction } from '../API/usersApi';
import { copyToClipboard } from '../utils/copyToClipboard';

const RequestLink = ({ children, onClose, type, link, aditionalStyles, content }) => (
  <a
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = '#efeef1';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = 'transparent';
    }}
    onClick={(e) => {
      if (typeof content === 'string') {
        copyToClipboard({ content });
      }
      onClose();
      saveUserAction(type === 'email' ? 'CLICKED_ANSWER_AS_EMAIL' : 'CLICKED_ANSWER_AS_CALL');
      e.stopPropagation();
    }}
    target="_blank"
    rel="noreferrer"
    href={type === 'email' ? `mailto:${link}` : `tel:${link}`}
    style={{
      color: 'inherit',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      gap: '10px',
      padding: '6px 30px 6px 15px',
      backgroundColor: 'transparent',
      width: '100%',
      ...aditionalStyles,
    }}>
    {children}
  </a>
);

export default RequestLink;
