import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePopup = ({ open, onClose, data, setDate, children }) => {
  const [period, setPeriod] = useState(data);
  const isDesktop = useMediaQuery('(min-width:600px)');

  const closeHandler = () => {
    setPeriod(data);
    onClose();
  };

  const setFilterHandler = () => {
    setDate(period);
    onClose();
  };

  const handleFromDateChange = (newValue) => {
    const value = newValue?.format('YYYY-MM-DD') || null;
    setPeriod((prevPeriod) => {
      const adjustedTo =
        prevPeriod.to && new Date(prevPeriod.to) < new Date(value) ? value : prevPeriod.to;
      return { ...prevPeriod, from: value, to: adjustedTo };
    });
  };

  const handleToDateChange = (newValue) => {
    const value = newValue?.format('YYYY-MM-DD') || null;
    if (new Date(value) >= new Date(period.from)) {
      setPeriod((prevPeriod) => ({ ...prevPeriod, to: value }));
    }
  };

  const clearDate = (field) => {
    setPeriod({ ...period, [field]: null });
  };
  return (
    <Dialog open={open} onClose={closeHandler}>
      <DialogTitle>Select Dates and Option</DialogTitle>
      <DialogContent>
        <FormControl fullWidth style={{ margin: '16px 0' }}>
          <InputLabel id="select-label">Chose date</InputLabel>
          <Select
            size="small"
            fullWidth
            labelId="select-label"
            value={period.type || ''}
            label="Chose date"
            onChange={(e) => setPeriod({ ...period, type: e.target.value })}>
            {children}
          </Select>
        </FormControl>
        <div
          style={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            gap: '20px',
          }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <DatePicker
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: 3,
                  },
                  '& .MuiFormLabel-root': {
                    marginTop: '-8px',
                  },
                  '& .MuiFormLabel-filled': {
                    marginTop: 0,
                  },
                }}
                size="small"
                label="From"
                value={period.from ? dayjs(period.from) : null}
                onChange={handleFromDateChange}
                disabled={period.type === 'off'}
              />
              <Button size="small" variant="contained" onClick={() => clearDate('from')}>
                Rensa
              </Button>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <DatePicker
                sx={{
                  '& .MuiInputBase-input': {
                    paddingY: 3,
                  },
                  '& .MuiFormLabel-root': {
                    marginTop: '-8px',
                  },
                  '& .MuiFormLabel-filled': {
                    marginTop: 0,
                  },
                }}
                label="To"
                value={period.to ? dayjs(period.to) : null}
                onChange={handleToDateChange}
                minDate={period.from && dayjs(period.from)}
                disabled={period.type === 'off'}
              />
              <Button size="small" variant="contained" onClick={() => clearDate('to')}>
                Rensa
              </Button>
            </div>
          </LocalizationProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" sx={{ margin: '0 16px 8px 0' }} onClick={setFilterHandler}>
          Filtrera
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DatePopup;
