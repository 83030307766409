import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box, ClickAwayListener, Container, IconButton, useMediaQuery } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getLead } from '../../API/leadApi';
import LeadContent from './LeadContent';
import Loader from '../Loader';
import { leadsParser } from '../../utils/leadsParser';
import RequestLeadsButton from '../RequestLeadButton';

const LeadPage = () => {
  const [lead, setLead] = useState();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [errorMassage, setErrorMessage] = useState('');
  const isDesktop = useMediaQuery('(min-width:600px)');
  const currentMessageId = window.location.href.split('/').pop();

  const navigate = useNavigate();

  const params = useParams();
  useEffect(() => {
    const getOneLead = async () => {
      setLoading(true);
      const res = await getLead(params.id);
      if (res.status === 200) {
        const data = leadsParser([res.data]);
        setLead(data[0]);
      }
      if (res.status === 400) {
        setErrorMessage(`Message ${currentMessageId} not found`);
      }
      if (res.status === 403) {
        setErrorMessage(`Access denied to message ${currentMessageId}`);
      }
    };
    getOneLead();

    setLoading(false);
  }, []);
  if (loading) {
    return <Loader />;
  }
  const onChangeMenuState = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <Container sx={{ background: 'white', padding: '16px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <IconButton style={{ cursor: 'pointer' }} onClick={() => navigate('/client/leads')}>
          <ArrowBackIcon sx={{ color: '#00B8B4' }} />
        </IconButton>
        {!isDesktop && (
          <IconButton
            style={{ cursor: 'pointer', position: 'relative' }}
            onClick={onChangeMenuState}>
            <MoreVertIcon sx={{ color: '#00B8B4' }} />
            {isOpenMenu && (
              <ClickAwayListener onClickAway={onChangeMenuState}>
                <Box
                  sx={{
                    position: 'absolute',
                    padding: '6px 0',
                    right: '150px',
                    top: '40px',
                    fontSize: '14px',
                    color: 'black',
                  }}>
                  <RequestLeadsButton lead={lead} onClose={onChangeMenuState} />
                </Box>
              </ClickAwayListener>
            )}
          </IconButton>
        )}
      </div>
      {lead ? (
        <LeadContent lead={lead} isOpen={isOpen} setIsOpen={(leadId) => setIsOpen(leadId)} />
      ) : (
        <h3 style={{ paddingLeft: '20px' }}>{errorMassage}</h3>
      )}
    </Container>
  );
};

export default LeadPage;
