import { useEffect, useState } from 'react';
import { getUsersList } from '../API/usersApi';

const useUserStatisticsTable = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(null);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUsersList({
          search: search.toLowerCase(),
          page,
          limit,
          role: 2,
          countActions: 'true',
          getLastLogin: 'true',
          sort: 'count_last_30_days_login',
          searchParams: 'companyName',
        });
        setUsers(data.users);
        setCount(data.count);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchData();
  }, [limit, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  return {
    users,
    count,
    page,
    search,
    limit,
    handleChangePage,
    searchInputHandler,
    handleChangeLimit,
  };
};

export default useUserStatisticsTable;
