import { useState, useEffect, useCallback } from 'react';
import { getMySites, getSiteStatistics, getSitesList } from '../API/siteApi';
import { debounce } from '../utils/debounce';
import { getLastSevenDaysRange, getLastThirtyDaysRange } from '../utils/timeJs';

const usePageChartHook = ({ userMe }) => {
  const [sites, setSites] = useState([]);
  const [currentSite, setCurrentSite] = useState(null);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState();
  const [period, setPeriod] = useState('month');

  const fetchSites = useCallback(
    debounce(async (userRole, searchValue) => {
      try {
        let fetchedSites;
        if (userRole === 'admin' || userRole === 'user') {
          const response = await getSitesList(searchValue, 0, 50, null, null, 'googleRank');
          fetchedSites = response.sites;
        } else {
          const response = await getMySites(searchValue, 0, 200, userRole, null, 'googleRank');
          fetchedSites = response.sites.sites;
        }

        setSites(fetchedSites);
      } catch (err) {
        console.error(err);
      }
    }, 250),
    [],
  );

  const clearInput = useCallback(() => {
    setCurrentSite({ domain: '' });
    setSearch('');
  }, []);

  useEffect(() => {
    if (sites.length && !currentSite) {
      setCurrentSite(sites[0]);
      setLoading(false);
    }
  }, [sites]);

  useEffect(() => {
    if (userMe) {
      fetchSites(userMe, search);
    }
  }, [userMe, search]);

  useEffect(() => {
    const getStats = async () => {
      if (currentSite?.nightwatchUrlId) {
        try {
          let statisticData;
          if (period === 'week') {
            statisticData = getLastSevenDaysRange();
          }
          if (period === 'month') {
            statisticData = getLastThirtyDaysRange();
          }
          const statistic = await getSiteStatistics(
            currentSite.nightwatchUrlId,
            statisticData.from,
            statisticData.to,
          );
          const interval = [];
          const intervalValue = [];
          statistic[0].url_series[0].series.forEach((day) => {
            interval.push(day[0]);
            intervalValue.push(day[1]);
          });
          setSeries({ allDay: [...interval], allValue: [...intervalValue] });
        } catch (err) {
          console.error(err);
        }
      }
    };
    getStats();
  }, [currentSite, period]);

  return {
    sites,
    currentSite,
    setCurrentSite,
    search,
    setSearch,
    loading,
    series,
    setSeries,
    period,
    setPeriod,
    clearInput,
  };
};

export default usePageChartHook;
