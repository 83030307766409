import { createContext, useEffect, useReducer, useState } from 'react';

import axios from '../utils/axios';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_USER } from '../const';
import { isUserRole, isValidToken, setSession } from '../utils/jwt';

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [userMe, setUserMe] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken =
          window.localStorage.getItem(ACCESS_TOKEN_USER) ||
          window.localStorage.getItem(ACCESS_TOKEN_NAME);
        if (accessToken) {
          const codeRole = isUserRole();
          setUserMe(codeRole);
        }
        if (accessToken && isValidToken(accessToken)) {
          let isTrueClient = '';
          if (window.localStorage.getItem(ACCESS_TOKEN_USER)) {
            setSession(ACCESS_TOKEN_USER, accessToken);
          } else {
            isTrueClient = '?isTrueClient=true';
            setSession(ACCESS_TOKEN_NAME, accessToken);
          }

          const response = await axios.get(`/api/my-pages/user/my${isTrueClient}`);
          const user = response.data;
          if (user && !user.isActive) {
            if (window.localStorage.getItem(ACCESS_TOKEN_USER)) {
              setSession(ACCESS_TOKEN_USER, null);

              window.location.href = '/admin';
            } else {
              window.localStorage.removeItem(ACCESS_TOKEN_NAME);
            }

            return;
          }

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    const response = await axios.post('api/my-pages/auth', {
      email,
      password,
    });

    const { token, user } = response.data;

    if (!user.isActive) {
      throw new Error('Account is inactive');
    }
    setSession(ACCESS_TOKEN_NAME, token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
    return user.role;
  };

  const setSignIn = async (token, user) => {
    setSession(ACCESS_TOKEN_NAME, token);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const signOut = async () => {
    setSession(ACCESS_TOKEN_NAME, null);
    dispatch({ type: SIGN_OUT });
  };

  const signInAsUser = (token) => {
    setSession(ACCESS_TOKEN_USER, token);
  };
  const signOutAsUser = () => {
    setSession(ACCESS_TOKEN_USER, null);
  };

  const authProviderValue = () => ({
    ...state,
    method: 'jwt',
    signIn,
    signOut,
    userMe,
    setUserMe,
    setSignIn,
    signInAsUser,
    signOutAsUser,
  });

  return <AuthContext.Provider value={authProviderValue()}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
