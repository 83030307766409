import { IconButton, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

import { requestUserInvite } from '../../API/usersApi';

const RequestUserInvite = ({
  isDisabled,
  setLoadingCell,
  clientId,
  setUsersList,
  setSuccessInviteList,
  isActive,
  userIndex,
}) => {
  const postRequestUserInvite = async () => {
    try {
      setLoadingCell(clientId);
      await requestUserInvite(clientId);

      setLoadingCell(null);
      setUsersList((prev) => {
        const newValue = [...prev];
        newValue[userIndex] = {
          ...newValue[userIndex],
          isInviteSent: true,
        };
        return newValue;
      });

      setSuccessInviteList((prev) => [...prev, clientId]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IconButton
      size="small"
      style={{ padding: 0, backgroundColor: 'transparent', border: 0 }}
      disabled={isDisabled}
      onClick={postRequestUserInvite}>
      <Tooltip title="Skicka inbjudan" arrow>
        <EmailIcon
          sx={{
            margin: '0 5px',
            cursor: 'pointer',
            fill: isActive ? '#00B8B4' : '#646363',
          }}
        />
      </Tooltip>
    </IconButton>
  );
};

export default RequestUserInvite;
