import { Layout, Settings, Sliders, UserPlus, Info } from 'react-feather';

const pagesSection = [
  {
    href: '/client/dashboard',
    title: 'Dashboard',
    icon: Sliders,
  },
  {
    href: 'pages',
    title: 'Sidor',
    icon: Layout,
  },
  {
    href: 'leads',
    title: 'Kundförfrågningar',
    icon: UserPlus,
  },
  {
    href: 'settings',
    title: 'Inställningar',
    icon: Settings,
  },
  {
    href: 'info',
    title: 'Info',
    icon: Info,
    children: [
      {
        href: 'info/upload',
        title: 'Upload images',
      },
      {
        href: 'info/form',
        title: 'Form',
      },
    ],
  },
];

const navItems = [
  {
    title: '',
    pages: pagesSection,
  },
];

export default navItems;
