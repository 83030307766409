import { TableRow } from '@mui/material';
import CompanyTableCell from './CompanyTableCell';

const CompanySitesTableRow = ({ site }) => (
  <TableRow>
    <CompanyTableCell>{site.domain}</CompanyTableCell>
    <CompanyTableCell sx={{ textAlign: 'end' }}>{site.googleRank}</CompanyTableCell>
    <CompanyTableCell sx={{ textAlign: 'end' }}>{site.totalLeads || 0}</CompanyTableCell>
    <CompanyTableCell sx={{ textAlign: 'end' }}>{site.lastMonthLeads || 0}</CompanyTableCell>
  </TableRow>
);

export default CompanySitesTableRow;
