import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Power } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { Tooltip, Menu, MenuItem, IconButton as MuiIconButton, Button } from '@mui/material';
import { Box } from '@mui/system';

import useAuth from '../../hooks/useAuth';
import { ACCESS_TOKEN_USER } from '../../const';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const NavbarUserDropdown = () => {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const { signOut, setUserMe, signOutAsUser } = useAuth();
  const isUserView = useMemo(
    () => window.localStorage.getItem(ACCESS_TOKEN_USER),
    [window.localStorage.getItem(ACCESS_TOKEN_USER)],
  );

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    setUserMe(null);
    navigate('/auth/sign-in');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        color: '#646363',
      }}>
      {isUserView && (
        <Button
          sx={[
            {
              backgroundColor: 'transparent',
              color: '#646363',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          ]}
          onClick={() => {
            signOutAsUser();

            window.location.href = '/admin/users';
          }}>
          Gå till Admin
        </Button>
      )}
      <Tooltip title="Account">
        <IconButton
          aria-owns={anchorMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large">
          <Power />
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </Box>
  );
};

export default NavbarUserDropdown;
