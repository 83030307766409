import React from 'react';
import { Helmet } from 'react-helmet-async';
import AddLogoToFooter from './AddLogoToFooter';

const PageWrapper = ({ title, bgColor, children, logo }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {logo ? <AddLogoToFooter bgColor={bgColor}>{children}</AddLogoToFooter> : children}
  </>
);

export default PageWrapper;
