import React from 'react';
import { Box, Typography, Stack } from '@mui/material';

const FILES_URL = 'https://sites-files-addictive.s3.eu-north-1.amazonaws.com/leadsFiles/';

export const LeadFiles = ({ lead }) => {
  if (!lead.leadFiles?.length) {
    return null;
  }

  return (
    <Box>
      <Typography
        sx={{
          marginTop: '2rem',
          color: 'black',
          fontSize: '14px',
          fontWeight: 500,
        }}>
        Files
      </Typography>
      <Stack sx={{ margin: '10px 20px 20px 20px' }} spacing={2}>
        {lead.leadFiles?.map((el) => (
          <a key={el.id} href={`${FILES_URL}${el.fileName}`} target="_blank" rel="noreferrer">
            {el.fileName}
          </a>
        ))}
      </Stack>
    </Box>
  );
};
