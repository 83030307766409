import {
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Box,
  Typography,
  Tooltip,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { changeSiteOwner, getUserSites } from '../../API/siteApi';
import DropdownInput from './DropdownInput';
import {
  changeCompanyName,
  changeClientSalesperson,
  changeUserData,
  deleteUser,
  getSalesperson,
  getUserById,
} from '../../API/usersApi';
import Loader from '../Loader';
import ChangeUserPassword from './ChangeUserPassword';
import { dateFormatter } from '../../utils/dateFormatter';
import useAuth from '../../hooks/useAuth';

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '15px',
  p: 8,
  minWidth: '320px',
};

const activeButtonStyle = {
  background: 'white',
  padding: '10px 20px',
  width: '100%',
  textAlign: 'center',
  fontWeight: 700,
};
const notActiveButtonStyle = {
  background: '#8585851a',
  color: '#00000033',
  width: '100%',
  textAlign: 'center',
  padding: '10px 20px',
  fontWeight: 700,
};

const buttonFieldStyle = {
  display: 'flex',
  width: '100%',
  maxWidth: '500px',
  border: '1px solid #85858580',
  borderRadius: '10px',
  overflow: 'hidden',
  cursor: 'pointer',
};

const ROLENAME = { 1: 'admin', 2: 'kund', 3: 'säljare', 4: 'användare' };

const AssignSiteOwner = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { userMe } = useAuth();
  const [currentUser, setCurrentUser] = useState();
  const userId = currentUser?.id;
  const { firstName, lastName, role, email } = currentUser || {};
  const [dataUserSites, setDataUserSites] = useState();
  const [open, setOpen] = useState(false);
  const [newDomain, setNewDomain] = useState();
  const [openChangeForm, setOpenChangeForm] = useState(null);
  const [newRole, setNewRole] = useState(role);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [loading, setLoading] = useState();
  const [salespersons, setSalespersons] = useState();
  const [currentSalespersonId, setCurrentSalespersonId] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [error, setError] = useState('');
  const [isActive, setIsActive] = useState();

  useMemo(async () => {
    const data = await getUserById(params.id);
    setCurrentUser({ ...data });
    setIsActive(data.isActive);
    setNewRole(data.role);
    return data;
  }, []);

  const getSites = async () => {
    try {
      const response = await getUserSites(userId, role);
      if (response.data.sites?.length) {
        setCurrentSalespersonId(response.data.sites[0].salespersonId || 0);
      }
      return setDataUserSites(response.data.sites);
    } catch (err) {
      return console.error(err);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getSites();
    }
  }, [role]);

  useEffect(() => {
    const changeIsActive = async () => {
      try {
        setLoading(true);
        await changeUserData({ isActive }, userId);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    if (currentUser) {
      changeIsActive();
    }
  }, [isActive]);

  const addSite = async () => {
    try {
      await changeSiteOwner(userId, newDomain, role);

      await getSites();
      setOpen(false);
      setNewDomain('');
    } catch (err) {
      console.error(err);
    }
  };

  const isDomainHandler = (value) => {
    setNewDomain(value);
  };

  const handleDelete = async (domain) => {
    try {
      await changeSiteOwner(null, domain, role);

      await getSites();
    } catch (err) {
      console.log(err.message);
    }
  };

  const saveChanges = async () => {
    setLoading(true);
    const allUserSites = dataUserSites.map((site) => site.domain);

    const data = { userId, companyName, sitesDomains: { ...allUserSites } };
    try {
      await changeCompanyName(data);
      setCurrentUser({ ...currentUser, companyName });
      setCompanyName('');
      setOpen(false);
    } catch (err) {
      console.error(err.message);
    }
    setLoading(false);
  };

  const deleteUserHandler = async () => {
    try {
      if (role === 3) {
        for (const site of dataUserSites) {
          changeSiteOwner(site.domain, userId, role);
        }
      }
      await deleteUser(userId);
      navigate(`/${userMe}/client`);
    } catch (err) {
      console.log(err.message);
    }
  };
  const getTitle = () => {
    if (lastName) {
      if (firstName) {
        return `${firstName} ${lastName}`;
      }
      return lastName;
    }
    if (firstName) {
      return firstName;
    }
    return 'Användare';
  };

  const getAllSalesperson = async () => {
    try {
      const { data } = await getSalesperson();
      setSalespersons(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllSalesperson();
  }, []);

  const createRowInUserCard = (rowName, rowValue) =>
    rowValue && (
      <Typography sx={{ fontSize: '14px' }}>
        <b>{rowName}: </b>
        {rowValue}
      </Typography>
    );

  const changeRoleHandler = async () => {
    setLoading(true);
    try {
      if (newRole !== role) {
        await changeUserData({ role: newRole }, userId);
        setCurrentUser({ ...currentUser, role: newRole });
      }
      setOpenChangeForm(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const changeClientSalespersonHandler = async () => {
    setLoading(true);
    try {
      await changeClientSalesperson(userId, { salespersonId: currentSalespersonId });
      setCurrentSalespersonId(0);
      setOpenChangeForm(null);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    userEmail: Yup.string().email('Must be a valid email').required('Email is required'),
  });

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.replaceAll(' ', '').toLowerCase();
    setUserEmail(emailValue);
  };

  const handleEmailBlur = () => {
    validationSchema
      .validate({ userEmail })
      .then(() => {
        setError('');
      })
      .catch((err) => {
        setError(err.errors[0]);
      });
  };

  const saveUserNewEmail = async () => {
    setLoading(true);
    try {
      await changeUserData({ email: userEmail }, userId);
      await changeClientSalesperson(userId, { email: userEmail });
      setCurrentUser({ ...currentUser, email: userEmail });
      setOpenChangeForm(null);
    } catch (err) {
      if (err.message === 'This email already exist') {
        setError(err.message);
      }
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={() => navigate(-1)}>Return</Button>

      <Card sx={{ marginBottom: '20px', padding: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              marginBottom: '10px',
              fontWeight: 500,
              fontSize: '26px',
              '@media (max-width: 600px)': {
                fontSize: '20px',
              },
            }}
            variant="h1">
            {getTitle()}
          </Typography>
        </div>
        {createRowInUserCard('Email', currentUser?.email)}
        {createRowInUserCard('Company name', currentUser?.companyName)}
        {createRowInUserCard('Role', ROLENAME[role])}
        {createRowInUserCard('Create', dateFormatter(currentUser?.createdAt))}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '12px',
          }}>
          <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
            <Button
              variant="contained"
              onClick={() => {
                if (openChangeForm === 'password') {
                  setOpenChangeForm(null);
                } else {
                  setOpenChangeForm('password');
                }
              }}>
              Ändra lösenord
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (openChangeForm === 'companyName') {
                  setOpenChangeForm(null);
                  setCompanyName('');
                } else {
                  setOpenChangeForm('companyName');
                }
              }}>
              Ändra Företagsnamn
            </Button>
            {userMe === 'admin' && (
              <Button
                variant="contained"
                onClick={() => {
                  if (openChangeForm === 'role') {
                    setOpenChangeForm(null);
                  } else {
                    setOpenChangeForm('role');
                  }
                }}>
                Ändra Roll
              </Button>
            )}
            {role === 2 && (
              <Button
                variant="contained"
                onClick={() => {
                  if (openChangeForm === 'user') {
                    setOpenChangeForm(null);
                  } else {
                    setOpenChangeForm('user');
                  }
                }}>
                Ändra Säljare
              </Button>
            )}
            {currentUser?.role !== 1 && (
              <Button
                variant="contained"
                onClick={() => {
                  if (openChangeForm === 'isActive') {
                    setOpenChangeForm(null);
                  } else {
                    setOpenChangeForm('isActive');
                  }
                }}>
                Aktiv
              </Button>
            )}

            <Button
              variant="contained"
              onClick={() => {
                if (openChangeForm === 'email') {
                  setOpenChangeForm(null);
                } else {
                  setUserEmail('');
                  setOpenChangeForm('email');
                }
              }}>
              Ändra Email
            </Button>
          </div>
          {(userMe === 'admin' || role === 2) && (
            <Button
              color="error"
              variant="text"
              sx={{ float: 'right', color: '#858585' }}
              onClick={() => setOpenConfirmModal(true)}>
              Radera konto
            </Button>
          )}
        </div>
        {openChangeForm === 'role' && (
          <Card sx={{}}>
            <RadioGroup
              sx={{ padding: '10px', gap: '5px' }}
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}>
              <FormControlLabel value={4} control={<Radio />} label="Användare" />
              <FormControlLabel value={3} control={<Radio />} label="Säljare" />
              <FormControlLabel value={1} control={<Radio />} label="Admin" />
              <FormControlLabel value={2} control={<Radio />} label="Kund" />
            </RadioGroup>
            <div>
              <Button
                sx={{ marginLeft: '5px' }}
                variant="outlined"
                size="medium"
                onClick={changeRoleHandler}>
                {loading ? <Loader /> : 'Spara Roll'}
              </Button>
            </div>
          </Card>
        )}
        {openChangeForm === 'user' && currentUser?.role === 2 && (
          <FormControl sx={{ width: '150px', marginTop: '20px' }}>
            <InputLabel id="salesperson-label">Säljare</InputLabel>
            <Select
              size="small"
              labelId="salesperson-label"
              value={currentSalespersonId}
              onChange={(e) => setCurrentSalespersonId(e.target.value)}
              label="Säljare">
              {salespersons?.map((sales) => (
                <MenuItem key={sales.id} value={sales.id}>
                  {sales.firstName} {sales.lastName || ''}
                </MenuItem>
              ))}
            </Select>
            <div>
              <Button
                sx={{ marginTop: '20px' }}
                variant="outlined"
                size="medium"
                onClick={changeClientSalespersonHandler}>
                {loading ? <Loader /> : 'Spara säljare'}
              </Button>
            </div>
          </FormControl>
        )}

        {openChangeForm === 'isActive' && currentUser?.role !== 1 && (
          <div style={{ marginTop: '20px' }}>
            {loading ? (
              <Loader />
            ) : (
              <div style={buttonFieldStyle}>
                <div
                  onClick={() => setIsActive(true)}
                  style={isActive ? activeButtonStyle : notActiveButtonStyle}>
                  Aktiv
                </div>
                <div
                  onClick={() => setIsActive(false)}
                  style={!isActive ? activeButtonStyle : notActiveButtonStyle}>
                  Inaktiv
                </div>
              </div>
            )}
          </div>
        )}
        {openChangeForm === 'email' && (
          <Card sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <TextField
              sx={{ marginTop: '15px', width: 250 }}
              label="Current Email"
              size="small"
              type="text"
              variant="outlined"
              value={email}
              readOnly
            />

            <TextField
              sx={{ margin: '15px 0', width: 250 }}
              label="New Email"
              size="small"
              type="email"
              variant="outlined"
              value={userEmail}
              onChange={(e) => handleEmailChange(e)}
              onBlur={handleEmailBlur}
              onFocus={() => setError('')}
              error={!!error}
              helperText={error}
            />
            <Button
              sx={{ marginBottom: '5px', width: 100 }}
              variant="outlined"
              size="medium"
              onClick={saveUserNewEmail}>
              {loading ? <Loader /> : 'Save'}
            </Button>
          </Card>
        )}
        {openChangeForm === 'companyName' && (
          <Card sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <TextField
              sx={{ marginTop: '10px', width: 250 }}
              label="Current Comapny Name"
              size="small"
              type="text"
              variant="outlined"
              value={currentUser?.companyName || 'No Company Name'}
              readOnly
            />
            <TextField
              sx={{ margin: '10px 0', width: 250 }}
              label="New Company Name"
              size="small"
              type="text"
              variant="outlined"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <Button
              sx={{ marginBottom: '5px', width: 100 }}
              variant="outlined"
              onClick={saveChanges}>
              {loading ? <Loader /> : 'Save'}
            </Button>
          </Card>
        )}
        {openChangeForm === 'password' && (
          <div style={{ paddingTop: '15px' }}>
            <ChangeUserPassword id={userId} title onClose={() => setOpenChangeForm(null)} />
          </div>
        )}
      </Card>

      <Card mb={6} sx={{ width: '100%' }}>
        <CardHeader
          action={
            userMe === 'admin' && (
              <Button variant="contained" sx={{ marginLeft: '20px' }} onClick={() => setOpen(true)}>
                Lägg till landningssida
              </Button>
            )
          }
          title="User Sites List"
        />

        <Paper>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>Page</TableCell>

                  <TableCell sx={{ whiteSpace: 'nowrap' }}>Site Id</TableCell>
                  <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                    User Id
                  </TableCell>
                  {userMe === 'admin' && (
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataUserSites?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.domain}
                    </TableCell>
                    <TableCell>{row.sanityId}</TableCell>
                    <TableCell align="center">{row.ownerId}</TableCell>
                    {userMe === 'admin' && (
                      <TableCell align="center">
                        <Tooltip title="Bort landningssida" arrow>
                          <DeleteIcon onClick={() => handleDelete(row.domain)} />
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      </Card>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Site
            <CloseIcon onClick={() => setOpen(false)} sx={{ float: 'right' }} />
          </Typography>
          <DropdownInput onChange={isDomainHandler} />

          <Button
            sx={{ marginTop: '10px', marginLeft: '10px' }}
            variant="outlined"
            onClick={addSite}>
            Add
          </Button>
        </Box>
      </Modal>

      <Modal
        sx={{ outline: 'none' }}
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Paper
            sx={{
              padding: '25px',
              maxWidth: '350px',
            }}>
            <Typography variant="h4">Radera konto?</Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '20px',
                gap: '20px',
              }}>
              <Button
                onClick={() => deleteUserHandler()}
                variant="contained"
                color="success"
                size="small">
                Ja
              </Button>
              <Button
                onClick={() => setOpenConfirmModal(false)}
                variant="contained"
                color="error"
                size="small">
                Nej
              </Button>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

export default AssignSiteOwner;
