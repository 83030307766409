import { useState } from 'react';

const useChangeOptimizationStageButton = ({ onChange, optimizationId, companyId }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setOpen(false);
  };

  const changeHandler = (e) => {
    setOpen(false);

    if (optimizationId !== null) {
      onChange({ e, optimizationId });
    } else {
      onChange({ e, companyId });
    }
  };

  const onClickButton = (e) => {
    setOpen(true);
    setAnchorEl(e.target);
  };
  return { open, anchorEl, onClose, changeHandler, onClickButton };
};

export default useChangeOptimizationStageButton;
