import styled from '@emotion/styled';

const ContentModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: white;
  border-radius: 5px;
  padding: 20px 15px;

  .content {
    position: relative;

    .icon-close {
      position: absolute;
      right: -50px;
      top: -50px;
    }

    h2 {
      margin: 0 0 15px;
    }

    .link-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
        font-weight: 500;
      }

      button {
        flex: 1;
      }
    }
  }
`;

export default ContentModalWrapper;
