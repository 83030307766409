import { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, CircularProgress, Box } from '@mui/material';

import useProposalPage from '../../hooks/proposal/useProposalPage';
import ProposalDetail from '../../components/proposal/ProposalDetail';
import ProposalPageButtons from './ProposalPageButtons';
import { GlobalContext } from '../../contexts/GlobalContext';
import ProposalDetailMap from '../../components/proposal/ProposalDetailMap';
import ProposalTable from '../../components/proposal/ProposalTable';

const ProposalPage = () => {
  const { id } = useParams();
  const blockRef = useRef();
  const { showAlert } = useContext(GlobalContext);
  const {
    proposal,
    isLoading,
    proposalDetails,
    handleInputChange,
    handleAddDetail,
    handleDeleteDetail,
    handleSave,
    isImporting,
    captureMap,
    handleKeyPress,
    inputRef,
  } = useProposalPage({ id, showAlert, blockRef });

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Box
      padding={isImporting ? 0 : 2}
      sx={{ width: isImporting ? '500px' : 'auto' }}
      bgcolor="#fff">
      <Grid sx={{ pt: 10 }} container justifyContent="space-between">
        {!isImporting && (
          <Grid
            spacing={isImporting ? 0 : 2}
            alignItems="flex-start"
            xs={isImporting ? 6 : 12}
            md={6}
            sx={{
              ml: {
                xs: 1,
                md: 0,
              },

              mb: {
                xs: 10,
                md: 1,
              },
            }}>
            <Typography sx={{ pl: 8 }} variant="h4" gutterBottom>
              Förslag till {proposal?.company?.name}
            </Typography>
            <Box
              sx={
                isImporting
                  ? { flexGrow: 1 }
                  : { flexGrow: 1, overflowY: 'auto', maxHeight: '650px', p: 5, pb: 0 }
              }>
              {isImporting ? (
                <ProposalTable details={proposalDetails} />
              ) : (
                proposalDetails.map((detail, index) => (
                  <ProposalDetail
                    detail={detail}
                    onChange={handleInputChange}
                    onDelete={handleDeleteDetail}
                    isShowDeleteButton={!isImporting}
                    handleKeyPress={handleKeyPress}
                    inputRef={index === proposalDetails.length - 1 ? inputRef : null}
                  />
                ))
              )}
            </Box>
            <ProposalPageButtons
              onAddDetail={handleAddDetail}
              onSave={handleSave}
              isShow={!isImporting}
            />
          </Grid>
        )}
        <Grid
          container
          spacing={isImporting ? 0 : 2}
          justifyContent={{ xs: 'center', md: isImporting ? 'flex-end' : 'center' }}
          xs={12}
          md={isImporting ? 12 : 6}
          sx={{
            ml: {
              xs: 0,
              md: 0,
            },
            mb: 0,
          }}
          ref={blockRef}>
          <ProposalDetailMap
            areas={proposalDetails.map((i) => i.area)}
            onMapSave={captureMap}
            isShowImportButton={!isImporting}
            handleAddDetail={handleAddDetail}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProposalPage;
