import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { Drawer as MuiDrawer, ListItemButton, Typography } from '@mui/material';
import logo from '../../vendor/pinpoint_white.png';

import SidebarNav from './SidebarNav';

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h1.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
    text-align: center;
    font-weight: 500;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Sidebar = ({ items, ...rest }) => (
  <Drawer variant="permanent" {...rest}>
    <Brand component={NavLink} to="/">
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <img style={{ maxHeight: '30px' }} src={logo} alt="" />
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          Mina sidor
        </Typography>
      </div>
    </Brand>
    <SidebarNav items={items} />
  </Drawer>
);

export default Sidebar;
