import { useState } from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Link, Modal, Typography } from '@mui/material';
import { DeleteForever, Close } from '@mui/icons-material';
import { deleteMyFile } from '../API/usersApi';

const ImageCard = ({ link, onChange }) => {
  const [open, setOpen] = useState(false);

  const deleteFileHandler = async (fileName) => {
    try {
      await deleteMyFile(fileName);
      await onChange();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={3}
      style={{ borderRadius: '6px', overflow: 'hidden' }}>
      <Card
        sx={{
          borderRadius: '6px',
        }}>
        <CardMedia
          onClick={() => setOpen(true)}
          sx={{
            width: '100%',
            height: '200px',
            backgroundPosition: 'center',
          }}
          image={link}
          alt="Addictive"
        />

        <CardContent
          style={{
            padding: '16px',
            borderTop: '1px solid #EDEDED',
            backgroundColor: '#ffffff',
            whiteSpace: 'nowrap',
          }}>
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            variant="p"
            component="div">
            <Link
              href={link}
              target="_blank"
              rel="noreferrer"
              sx={{
                color: 'black',
                height: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {link.split('/').pop()}
            </Link>
            <DeleteForever
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => deleteFileHandler(link)}
              color="error"
            />
          </Typography>
        </CardContent>
      </Card>
      <Modal
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={open}
        onClose={() => setOpen(false)}>
        <Box
          sx={{
            maxWidth: '80vw',
            maxHeight: '80vh',
            outline: 'none',
            position: 'relative',
          }}>
          <img
            style={{ maxWidth: '80vw', maxHeight: '80vh' }}
            src={link}
            alt={link.split('/').pop()}
          />
          <span
            onClick={() => setOpen(false)}
            style={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              padding: '2px',
              borderRadius: '50%',
              background: 'white',
              width: '19px',
              height: '19px',
              cursor: 'pointer',
            }}>
            <Close sx={{ width: ' 15px', height: '15px' }} />
          </span>
        </Box>
      </Modal>
    </Grid>
  );
};

export default ImageCard;
