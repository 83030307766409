import { useEffect, useState } from 'react';

import { Divider as MuiDivider, Grid, Typography as MuiTypography } from '@mui/material';

import styled from '@emotion/styled';
import { spacing } from '@mui/system';

import Stats from '../../pages/dashboards/Default/Stats';
import { getDashboard } from '../../API/usersApi';
import Loader from '../Loader';
import MapPage from './MapPage/MapPage';
import { getMySites } from '../../API/siteApi';
import useAuth from '../../hooks/useAuth';

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const DashboardPage = () => {
  const [stats, setStats] = useState();
  const [data, setData] = useState([]);
  const { userMe } = useAuth();
  useEffect(() => {
    const getStats = async () => {
      const response = await getDashboard();
      setStats(response);
    };
    const getData = async () => {
      const response = await getMySites('', 0, 1000, userMe);
      setData(response.sites?.sites);
    };

    getData();
    getStats();
  }, []);

  if (!stats) {
    return <Loader />;
  }
  return (
    <>
      <Grid justifyContent="space-between" container>
        <Grid item sx={{ margin: '10px 0 15px' }}>
          <Typography
            sx={{
              fontWeight: 500,
              '@media (max-width: 600px)': {
                fontSize: '30px',
              },
            }}
            variant="h1"
            gutterBottom>
            {stats?.companyName || 'Dashboard'}
          </Typography>
          <Typography variant="h3" sx={{ fontStyle: 'italic', fontWeight: '500' }}>
            Välkommen tillbaka!
          </Typography>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Stats title="Total Leads" amount={stats.leadsCount} chip="Annual" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Stats title="Total New Leads" amount={stats.newLeadsCount} chip="Annual" />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Stats title="Total Sites" amount={stats.siteCount} chip="Annual" />
        </Grid>
      </Grid>

      {data && (
        <Grid container spacing={6}>
          <Grid item xs={12} lg={9}>
            <MapPage data={data} isPin />
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default DashboardPage;
