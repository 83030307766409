import swedenGeoData from '../sweden.json';

const showOnlySwedenOnMap = () => {
  const worldPolygon = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-180, -90],
          [180, -90],
          [180, 90],
          [-180, 90],
          [-180, -90],
        ],
      ],
    },
  };

  swedenGeoData.features.forEach((feature) => {
    feature.geometry.coordinates.forEach((part) => {
      worldPolygon.geometry.coordinates.push(...part);
    });
  });
  return worldPolygon;
};

export default showOnlySwedenOnMap;
