import { Link } from '@mui/material';

const LinkWithGoogleRank = ({ site, withBorder }) => (
  <>
    <Link
      href={`https://${site.domain}`}
      target="_blank"
      rel="noreferrer"
      sx={{
        color: 'black',
        height: '100%',
        maxWidth: '100%',
      }}>
      {site.domain}
    </Link>
    <br />
    <span style={{ color: site.isAvailable ? '#50B4B2' : '#C7468B' }}>
      Google rank: {site.googleRank}
      <br />
      Leads: {site.leadCount}
    </span>
    <br />
    {withBorder && <hr style={{ margin: '2px', opacity: 0.2 }} />}
  </>
);

export default LinkWithGoogleRank;
