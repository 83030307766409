import axios from '../utils/axios';

export const getUsersCompanyList = async ({
  search,
  page,
  limit,
  type,
  salespersonId,
  sort,
  reverseSort,
  dateFilter,
}) => {
  const decodedSearch = search?.replaceAll('&', '%26');
  let query = `type=${type}`;
  if (search?.length > 0) {
    query += `&name=${decodedSearch}`;
  }
  if (page !== 0) {
    query += `&offset=${page * limit}`;
  }
  if (limit !== 50) {
    query += `&limit=${limit}`;
  }
  if (salespersonId > 0) {
    query += query.length ? `&salespersonId=${salespersonId}` : `salespersonId=${salespersonId}`;
  }
  if (sort) {
    query += `&sortField=${sort}`;
  }
  if (reverseSort) {
    query += '&sortOrder=DESC';
  }
  if (dateFilter && dateFilter.type && dateFilter.type !== 'off') {
    query += `&dateField=${dateFilter.type}`;
    if (dateFilter.from) {
      query += `&startDate=${dateFilter.from}`;
    }
    if (dateFilter.to) {
      query += `&endDate=${dateFilter.to}`;
    }
  }
  const { data } = await axios.get(`/api/my-pages/company/listing?${query}`);
  return data;
};

export const getOptimizationList = async ({ stage = null, sort, reverseSort, limit, page }) => {
  let query = '';
  if (stage !== null) {
    query += query.length ? `&stage=${stage}` : `?stage=${stage}`;
  }
  if (sort) {
    query += query.length ? `&orderingField=${sort}` : `?orderingField=${sort}`;
    if (reverseSort) {
      query += '&orderingDirection=DESC';
    }
  }
  if (limit && limit !== 20) {
    query += query.length ? `&limit=${limit}` : `?limit=${limit}`;
  }
  if (page && page !== 0) {
    query += query.length ? `&offset=${page * limit}` : `?offset=${page * limit}`;
  }
  const { data } = await axios.get(`/api/optimization${query}`);
  return data;
};

export const putOptimization = async ({ id, stage, comment = null }) => {
  const body = {};
  if (id) {
    if (stage) {
      body.stage = stage;
    }
    if (comment !== null) {
      body.comment = comment;
    }
    const { data } = await axios.put(`/api/optimization/${id}`, body);
    return data;
  }
  return null;
};

export const countOptimizationListByStage = async () => {
  const { data } = await axios.get('/api/optimization/count');
  return data;
};

export const getAllSitesCompany = async (companyName) => {
  try {
    const response = await axios.get(`/api/my-pages/company/sites?companyName=${companyName}`);
    return response.data;
  } catch (err) {
    return console.log(err);
  }
};

export const getCompanies = async ({ page = 1, limit = 10, search }) => {
  try {
    const { data } = await axios.get('/api/my-pages/company', {
      params: {
        page,
        limit,
        search,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};

export const createOptimization = async ({ companyId, stage, comment }) => {
  const body = { companyId, stage, comment };

  const { data } = await axios.post('/api/optimization/', body);
  return data;
};

export const putCompany = async ({ id, leadsExpectation }) => {
  try {
    if (id) {
      let query = `?id=${id}`;
      if (leadsExpectation) {
        query += `&leadsExpectation=${leadsExpectation}`;
      }
      const { data } = await axios.put(`/api/my-pages/company${query}`);
      return data.message;
    }
    throw new Error('Company ID is required');
  } catch (error) {
    throw new Error(error);
  }
};
