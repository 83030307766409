import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
import axios from './axios';
import { ACCESS_TOKEN_NAME, ACCESS_TOKEN_USER } from '../const';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now();

  return decoded.iat < currentTime;
};

const isUserRole = () => {
  const accessToken =
    window.localStorage.getItem(ACCESS_TOKEN_USER) ||
    window.localStorage.getItem(ACCESS_TOKEN_NAME);
  if (accessToken) {
    const decoded = jwtDecode(accessToken);
    let role;
    if (decoded.role === 1) {
      role = 'admin';
    } else if (decoded.role === 2) {
      role = 'client';
    } else if (decoded.role === 3) {
      role = 'salesperson';
    } else {
      role = 'user';
    }
    return role;
  }
  return null;
};

const isUser = () => {
  const accessToken =
    window.localStorage.getItem(ACCESS_TOKEN_USER) ||
    window.localStorage.getItem(ACCESS_TOKEN_NAME);
  if (accessToken) {
    const decoded = jwtDecode(accessToken);
    return decoded;
  }
  return null;
};

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//   }, timeLeft);
// };

const setSession = (tokenName, accessToken) => {
  if (accessToken) {
    window.localStorage.setItem(tokenName, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    window.localStorage.removeItem(tokenName);
    delete axios.defaults.headers.common.Authorization;
  }
};

export { verify, sign, isValidToken, setSession, isUserRole, isUser };
