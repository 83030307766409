import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  ClickAwayListener,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LeadContent from './UserPage/LeadContent';
import { LeadFiles } from './UserPage/LeadFiles';
import { dateFormatter } from '../utils/dateFormatter';
import Loader from './Loader';
import { getSource } from '../utils/getSource';
import RequestLeadsButton from './RequestLeadButton';

const LeadCard = ({
  lead,
  deleteLeadHandler,
  loading,
  tableRowClickHandler,
  isClient,
  isOpenLead,
  setIsOpenLead,
  isOpenMenu,
  setIsOpenMenu,
}) => {
  const handleClose = (e) => {
    setIsOpenLead(null);
    e.stopPropagation();
  };
  const changeMenuStateHandler = (e) => {
    if (isOpenMenu) {
      setIsOpenMenu(null);
      e?.stopPropagation();
    } else {
      setIsOpenMenu(lead.id);
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{ marginBottom: 2, overflow: 'scroll' }}
      style={{
        backgroundColor: lead.isNew ? '#fff' : 'rgba(237, 247, 249, 0.5)',
      }}>
      <CardContent
        onClick={() => {
          setIsOpenLead(lead.id);

          tableRowClickHandler(lead.id, lead.isNew);
        }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" gutterBottom sx={{ margin: 0 }}>
            {getSource(lead.source)}
          </Typography>
          {!isClient && (
            <Tooltip title="Delete" arrow>
              <IconButton onClick={() => deleteLeadHandler(lead.id)}>
                {loading === lead.id ? <Loader /> : <Close />}
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Typography variant="subtitle1" gutterBottom>
          {lead.subjectEmail}
        </Typography>
        {!isClient && (
          <Typography variant="subtitle1" gutterBottom>
            {lead.domain}
          </Typography>
        )}
        <Typography variant="subtitle2" gutterBottom>
          {dateFormatter(lead.createdAt)}
        </Typography>{' '}
        <Dialog
          fullScreen
          open={isOpenLead}
          onClose={handleClose}
          style={{ backgroundColor: '#EDF7F9' }}>
          <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton
                style={{ cursor: 'pointer', transform: 'translate(-10px)' }}
                onClick={handleClose}>
                <ArrowBackIcon sx={{ color: '#00B8B4' }} />
              </IconButton>{' '}
              {isClient && (
                <IconButton
                  style={{ cursor: 'pointer', position: 'relative' }}
                  onClick={changeMenuStateHandler}>
                  <MoreVertIcon sx={{ color: '#00B8B4' }} />
                  {isOpenMenu && (
                    <ClickAwayListener onClickAway={changeMenuStateHandler}>
                      <Box
                        sx={{
                          position: 'absolute',
                          padding: '6px 0',
                          right: '150px',
                          top: '40px',
                          fontSize: '14px',
                          color: 'black',
                        }}>
                        <RequestLeadsButton lead={lead} onClose={changeMenuStateHandler} />
                      </Box>
                    </ClickAwayListener>
                  )}
                </IconButton>
              )}
            </div>
            <LeadContent lead={lead} />
            <LeadFiles lead={lead} />
            {isClient && <RequestLeadsButton lead={lead} />}
          </div>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default LeadCard;
