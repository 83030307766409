import axios from '../utils/axios';

export const getSitesList = async (
  search,
  page = 0,
  limit = 20,
  isAvailable = 2,
  isLocation = false,
  sortingField = null,
  sortingDirection = null,
) => {
  let query = '';
  if (search?.length > 0) {
    query += `search=${search}`;
  }
  if (isAvailable < 2) {
    query += query.length ? `&isAvailable=${isAvailable}` : `isAvailable=${isAvailable}`;
  }
  if (page !== 0) {
    query += `&page=${page}`;
  }
  if (limit !== 20) {
    query += `&limit=${limit}`;
  }
  if (isLocation) {
    query += `&isLocation=${isLocation}`;
  }
  if (sortingField) {
    query += `&sortingField=${sortingField}`;
  }
  if (sortingDirection) {
    query += `&sortingDirection=${sortingDirection}`;
  }
  const { data } = await axios.get(`/api/my-pages/site?${query}`);

  return data;
};

export const changeSiteOwner = async (userId, newDomain, role) => {
  try {
    const response = await axios.put(
      `/api/my-pages/site?userId=${userId}&domain=${newDomain}&role=${role}`,
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const getUserSites = async (userId, role) => {
  const response = await axios.get(`/api/my-pages/site/site?userId=${userId}&role=${role}`);
  return response;
};

export const getMySites = async (
  search,
  page = 0,
  limit = 20,
  role = 'client',
  isLocation = false,
) => {
  let query = '';
  try {
    if (search?.length > 0) {
      query += `search=${search}`;
    }
    if (page !== 0) {
      query += `&page=${page}`;
    }
    if (limit !== 20) {
      query += `&limit=${limit}`;
    }
    if (role) {
      query += `&role=${role === 'client' ? 2 : 3}`;
    }
    if (isLocation) {
      query += `&isLocation=${isLocation}`;
    }
    const response = await axios.get(`/api/my-pages/site/my?${query}`);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const getSiteStatistics = async (siteId, from, to) => {
  const { data } = await axios.get(
    `/api/my-pages/dashboard/siteStatistics?siteId=${siteId}&from=${from}&to=${to}`,
  );
  if (data.data) {
    return data.data;
  }
  return data;
};
