import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';

import Loader from '../Loader';
import { getRequestUserInvite } from '../../API/usersApi';
import ContentModalWrapper from '../styledComponents/ContentModalWrapper';

const LinkToInviteUserModal = ({ clientId }) => {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const getLintToInviteClient = async () => {
    try {
      const { data } = await getRequestUserInvite(clientId);

      setLink(data.link);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    if (!link) {
      getLintToInviteClient();
    }

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setIsLinkCopied(true);

    setTimeout(() => {
      setIsLinkCopied(false);
    }, 5000);
  };

  return (
    <div>
      <IconButton size="small" onClick={handleOpen}>
        <LinkIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ContentModalWrapper>
          <div className="content">
            <IconButton className="icon-close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <h2>Link to invite a client</h2>
            {link ? (
              <div className="link-block">
                <p>{link}</p>
                <IconButton disabled={isLinkCopied} onClick={handleCopyLink}>
                  {isLinkCopied ? <CheckCircleOutlineIcon color="success" /> : <ContentCopyIcon />}
                </IconButton>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </ContentModalWrapper>
      </Modal>
    </div>
  );
};

export default LinkToInviteUserModal;
