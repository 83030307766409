import { TableBody, Table } from '@mui/material';
import Loader from './Loader';
import CompanySiteTableHead from './CompanySiteTableHead';
import CompanySitesTableRow from './CompanySiteTableRow';
import useCompanySiteTable from '../hooks/useCompanySiteTable';

const CompanySitesTable = ({ companyName }) => {
  const { isLoading, sites } = useCompanySiteTable({ companyName });

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Table style={{ border: '1px solid #e0e0e0', margin: '15px 0' }}>
      <CompanySiteTableHead />
      <TableBody>
        {sites?.length > 0 &&
          sites.map((site) => <CompanySitesTableRow key={site.id} site={site} />)}
      </TableBody>
    </Table>
  );
};

export default CompanySitesTable;
