import { Button, Grid, Input, InputLabel, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from 'react';
import { postUserFile } from '../API/usersApi';

const InfoDownloadFile = ({ onChange }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { file: selectedFile };
      await postUserFile(data);
      setSelectedFile(null);
      onChange();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Typography sx={{ paddingBottom: '20px' }} variant="h2">
        File Upload Form
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel htmlFor="contained-button-file">
              <Input
                inputProps={{ accept: 'image/*' }}
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                Upload image
              </Button>{' '}
              {selectedFile && (
                <Button type="submit" variant="contained">
                  Submit {selectedFile.name || ''}
                </Button>
              )}
            </InputLabel>
            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected File"
                style={{ maxWidth: '100%', maxHeight: '50vh', marginTop: '10px' }}
              />
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default InfoDownloadFile;
