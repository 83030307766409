import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import LinkToInviteUserModal from './adminClientList/LinkToInviteUserModal';
import RequestUserInvite from './adminClientList/RequestUserInvite';
import useAuth from '../hooks/useAuth';
import { formatDate } from '../utils/timeJs';

const ClientCard = ({
  client,
  loadingCell,
  clientIconClickHandler,
  successInviteList,
  setLoadingCell,
  setSuccessInviteList,
  userIndex,
  setUsersList,
}) => {
  const navigate = useNavigate();
  const { userMe } = useAuth();
  return (
    <Card variant="outlined">
      <CardContent sx={{ position: 'relative' }}>
        <Typography sx={{ fontWeight: 700 }}>{client.companyName || ''}</Typography>
        {client.lastLogin && (
          <Typography>Senaste inloggning: {formatDate(client.lastLogin)}</Typography>
        )}
        <Typography>Besök 30 dagar: {client.count_last_30_days_login}</Typography>
        <Typography>Email Flow: {client.isNewEmailFlow ? 'New' : 'Old'}</Typography>
        <Typography style={{ fontWeight: 500 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            Actions :
            {client.role > 1 && (
              <Tooltip title="Kundvy" arrow>
                <IconButton size="small" onClick={() => clientIconClickHandler(client.email)}>
                  <PersonIcon
                    sx={{
                      cursor: 'pointer',
                      fill: client.isInvited ? '#00B8B4' : '#646363',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <LinkToInviteUserModal clientId={client.id} />
            {!client.isInvited && loadingCell !== client.id && (
              <RequestUserInvite
                isDisabled={successInviteList.includes(client.id)}
                clientId={client.id}
                setLoadingCell={setLoadingCell}
                setUsersList={setUsersList}
                setSuccessInviteList={setSuccessInviteList}
                isActive={client.isInviteSent}
                userIndex={userIndex}
              />
            )}
            <Tooltip title="Inställningar" arrow>
              <IconButton
                size="small"
                onClick={() => {
                  navigate(`/${userMe}/client/${client.id}`);
                }}>
                <Settings sx={{ cursor: 'pointer', fill: '#646363' }} />
              </IconButton>
            </Tooltip>
            {loadingCell === client.id && (
              <Box>
                <CircularProgress sx={{ height: '22px !important', width: '22px !important' }} />
              </Box>
            )}
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ClientCard;
