import React, { useCallback, useState } from 'react';
import { FormControl, TextField, Button, Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { userInviteConfirmation } from '../../API/usersApi';
import useAuth from '../../hooks/useAuth';

const ConfirmInviteForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSignIn } = useAuth();

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const tokenInvite = params.get('token');

  const formik = useFormik({
    initialValues: { password: '', password2: '' },
    validationSchema: Yup.object().shape({
      password: Yup.string().max(255).required('Password is required'),
      password2: Yup.string().max(255).required('Repeat password'),
    }),
    onSubmit: async ({ password, password2 }) => {
      if (password && password2 && password !== password2) {
        formik.setErrors({ password2: 'Lösenord behöver vara samma' });
        return;
      }
      try {
        setIsLoading(true);

        const {
          data: { token, user },
        } = await userInviteConfirmation(tokenInvite, password);

        setSignIn(token, user);
        navigate('/');
      } catch (err) {
        const message = err.message || 'Something went wrong';

        formik.setErrors({ password: message });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const inputHandler = useCallback(
    (e) => {
      formik.setFieldValue('password', e.target.value);
    },
    [formik],
  );
  const inputSecondHandler = useCallback(
    (e) => {
      formik.setFieldValue('password2', e.target.value);
    },
    [formik],
  );

  if (!tokenInvite) {
    return <Typography textAlign="center">No token</Typography>;
  }

  return (
    <FormControl
      sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
      <TextField
        sx={{ marginBottom: '15px' }}
        name="password"
        label="Nytt lösenord"
        value={formik.values.password}
        fullWidth
        error={Boolean(formik.touched.password && formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        onChange={inputHandler}
      />
      <TextField
        sx={{ marginBottom: '15px' }}
        name="password2"
        label="Repetera nytt lösenord"
        value={formik.values.password2}
        fullWidth
        error={Boolean(formik.touched.password2 && formik.errors.password2)}
        helperText={formik.touched.password2 && formik.errors.password2}
        onChange={inputSecondHandler}
      />
      <Button
        type="submit"
        onClick={() => formik.handleSubmit()}
        fullWidth
        variant="contained"
        sx={{ backgroundColor: '#006f97', fontSize: '16px' }}
        disabled={isLoading}>
        Logga in
      </Button>
    </FormControl>
  );
};

export default ConfirmInviteForm;
