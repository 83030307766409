export const LEADS_EXPECTATION = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
};

export const LEADS_EXPECTATION_NAMES = {
  [`${LEADS_EXPECTATION.SMALL}`]: '3-5 leads/30dgr',
  [`${LEADS_EXPECTATION.MEDIUM}`]: '6-10 leads/30dgr',
  [`${LEADS_EXPECTATION.LARGE}`]: '10+ leads/30dgr',
};
