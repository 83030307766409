import { TextField } from '@mui/material';
import useOptimizationTextArea from '../../hooks/Optimization/useOptimizationTextArea';

const OptimizationTextArea = ({ defaultValue, optimizationsId, changeComment }) => {
  const { comment, handleInputChange } = useOptimizationTextArea({
    defaultValue,
    optimizationsId,
    changeComment,
  });
  return (
    <TextField
      multiline
      sx={{
        width: '100%',
        '& .MuiInputBase-multiline': {
          padding: '8px',
        },
        '& .MuiInputBase-input': {
          fontSize: '12px',
        },
      }}
      minRows={3}
      maxRows={8}
      size="sm"
      variant="outlined"
      value={comment}
      name="notes"
      onChange={handleInputChange}
    />
  );
};

export default OptimizationTextArea;
