import { Button } from '@mui/material';

const BooleanButton = ({ name, isActive, onChange }) => (
  <Button
    sx={{
      fontWeight: 400,
      color: !isActive ? '#616161' : 'primary',
      border: !isActive ? '1px solid #bdbdbd' : '',

      '&:hover': {
        border: !isActive ? '1px solid #bdbdbd' : '',
      },
    }}
    variant={isActive ? 'contained' : 'outlined'}
    onClick={() => onChange(!isActive)}>
    {name}
  </Button>
);

export default BooleanButton;
