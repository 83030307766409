import { useCallback, useContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import {
  createOptimization,
  getUsersCompanyList,
  putCompany,
  putOptimization,
} from '../API/companyApi';
import { getSalesperson, getTokenAsUser } from '../API/usersApi';
import useAuth from './useAuth';
import { GlobalContext } from '../contexts/GlobalContext';
import { isUser } from '../utils/jwt';

const useClientCompanyList = () => {
  const { fetchOptimizationAmounts, showAlert } = useContext(GlobalContext);
  const { signInAsUser } = useAuth();
  const [clientCompaniesList, setClientCompaniesList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState({ type: 'off', from: null, to: null });
  const [currentSalespersonId, setCurrentSalespersonId] = useState(0);
  const [salespersons, setSalespersons] = useState();
  const [expandedId, setExpandedId] = useState(null);
  const [type, setType] = useState('active');
  const [loadingCell, setLoadingCell] = useState(null);
  const [sort, setSort] = useState('');
  const [reverseSort, setReversSort] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [openSelect, setOpenSelect] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateLoader, setUpdateLoader] = useState(false);

  const userInfo = isUser();

  const isDesktop = useMediaQuery('(min-width:600px)');

  const changeSort = (currentSort) => {
    if (currentSort === sort) {
      setReversSort(!reverseSort);
    } else {
      setSort(currentSort);
      setReversSort(false);
    }
    setPage(0);
  };

  const changeDateFilter = (date) => {
    if (date.type !== 'off') {
      setSort(date.type);
      setReversSort(true);
    } else {
      setSort('');
    }
    setDateFilter(date);
  };

  const updateUserCompany = async () => {
    setLoadingCell(0);
    const data = await getUsersCompanyList({
      search,
      page,
      limit,
      type,
      salespersonId: currentSalespersonId,
      sort,
      reverseSort,
      dateFilter,
    });
    setCount(data.count);
    setClientCompaniesList(data.companies);
    setLoadingCell(null);
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const typeHandler = (e) => {
    setPage(0);
    setType(e.target.value);
  };

  const getSalespersonNamesString = (salesId) => {
    let salesman = '';
    if (salesId) {
      const findOne = salespersons?.find((sales) => sales.id === salesId);
      if (findOne) {
        salesman = `${findOne.firstName} ${findOne.lastName?.length ? findOne.lastName : ''}`;
      }
    }
    return salesman;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const clickHandler = (id) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  useEffect(() => {
    updateUserCompany();
  }, [search, page, limit, currentSalespersonId, type, sort, reverseSort, dateFilter]);

  useEffect(() => {
    const getAllSalesperson = async () => {
      const { data } = await getSalesperson();
      setSalespersons(data);
    };

    getAllSalesperson();
  }, []);

  const openUserView = async ({ event, companyName }) => {
    try {
      event.stopPropagation();
      const {
        data: { token },
      } = await getTokenAsUser({ companyName });
      signInAsUser(token);
      window.location.href = '/';
    } catch (e) {
      showAlert({ text: e.data?.message || 'Something went wrong', typeOfAlert: 'error' });
    }
  };

  const changeComment = useCallback((id, comment) => {
    setClientCompaniesList((prevList) =>
      prevList.map((company) => {
        if (company.optimization?.id === id) {
          return { ...company, optimization: { ...company.optimization, comment } };
        }
        return company;
      }),
    );
  }, []);

  const onChange = useCallback(
    async ({ e, optimizationId, companyId }) => {
      try {
        if (optimizationId) {
          await putOptimization({ id: optimizationId, stage: e.target.value });
        } else {
          await createOptimization({ companyId, stage: e.target.value, comment: newComment });
        }
        setExpandedId(null);
        updateUserCompany();
        fetchOptimizationAmounts();
      } catch (error) {
        console.error('Error updating optimization:', error);
      }
    },
    [newComment],
  );

  const onCloseLeadExpectationMenu = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
    setOpenSelect(null);
  };

  const onChangeLeadsExpectation = async (event) => {
    try {
      event.stopPropagation();
      setUpdateLoader(true);
      const message = await putCompany({ id: openSelect, leadsExpectation: event.target.value });
      setClientCompaniesList((prevList) =>
        prevList.map((company) => {
          if (company.id === openSelect) {
            return { ...company, company_leads_expectation_id: event.target.value };
          }
          return company;
        }),
      );
      onCloseLeadExpectationMenu(event);
      showAlert({ text: message || 'Done', typeOfAlert: 'success' });
    } catch (e) {
      showAlert({ text: e.data?.message || 'Something went wrong', typeOfAlert: 'error' });
    } finally {
      setUpdateLoader(false);
    }
  };

  return {
    clientCompaniesList,
    search,
    page,
    count,
    limit,
    openDateFilter,
    dateFilter,
    currentSalespersonId,
    expandedId,
    type,
    loadingCell,
    sort,
    reverseSort,
    isDesktop,
    salespersons,
    newComment,
    openSelect,
    anchorEl,
    updateLoader,
    userInfo,
    onCloseLeadExpectationMenu,
    setAnchorEl,
    setOpenSelect,
    onChangeLeadsExpectation,
    setNewComment,
    setCurrentSalespersonId,
    setOpenDateFilter,
    changeSort,
    changeDateFilter,
    searchInputHandler,
    typeHandler,
    getSalespersonNamesString,
    handleChangePage,
    handleChangeLimit,
    clickHandler,
    openUserView,
    changeComment,
    onChange,
    setExpandedId,
  };
};

export default useClientCompanyList;
