import { Line } from 'react-chartjs-2';
import { Card, CardContent, CardHeader } from '@mui/material';

const AverageRatingChart = ({ chartData }) => {
  const data = {
    labels: chartData.dates,
    datasets: [
      {
        tension: 0.4,
        data: chartData.value,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
      },

      y: {
        reverse: true,
        grid: {
          color: 'rgba(0,0,0,0.0375)',
          fontColor: '#fff',
        },
        ticks: {
          callback: (value) => (Number.isInteger(Number(value)) ? value : ''),
        },
        title: {
          display: true,
        },
      },
    },
  };

  return (
    <Card>
      <CardHeader title="Snitt ranking" />
      <CardContent sx={{ paddingTop: 0 }}>
        <div style={{ minHeight: '200px' }}>
          <Line data={data} options={options} />
        </div>
      </CardContent>
    </Card>
  );
};

export default AverageRatingChart;
