import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';

import { Paper } from '@mui/material';

import SignInComponent from '../../components/auth/SignIn';
import logo from '../../vendor/login_logo.png';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};token, option
  }
`;

const SignIn = () => (
  <div style={{ height: '100vh', paddingTop: '10vh' }}>
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <img
        style={{ maxWidth: '80px', width: '100%', paddingBottom: '20px' }}
        alt="logo"
        src={logo}
      />
      <h2 style={{ padding: '0 10px', fontSize: '17px' }}>Logga in på Mina Sidor</h2>
    </div>
    <Wrapper>
      <Helmet title="Sign In" />
      <SignInComponent />
    </Wrapper>
  </div>
);

export default SignIn;
