import { TextField, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ProposalDetail = ({
  detail,
  onChange,
  onDelete,
  isShowDeleteButton = true,
  handleKeyPress,
  inputRef,
}) => (
  <Grid xs={12} container direction="row" sx={{ alignSelf: 'flex-start', mt: 2 }}>
    <Grid item xs sx={{ p: 3 }}>
      <TextField
        inputRef={inputRef}
        label="Keyword"
        variant="outlined"
        fullWidth
        value={detail.keyword}
        size="small"
        onChange={(e) => onChange(detail.id, 'keyword', e.target.value)}
        onKeyUp={handleKeyPress}
      />
    </Grid>
    <Grid item xs sx={{ p: 3 }}>
      <TextField
        label="Area"
        variant="outlined"
        fullWidth
        value={detail.area}
        size="small"
        onChange={(e) => onChange(detail.id, 'area', e.target.value)}
        onKeyUp={handleKeyPress}
      />
    </Grid>
    {isShowDeleteButton && (
      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => onDelete(detail.id)}>
          <DeleteIcon fontSize="small" color="disabled" />
        </IconButton>
      </Grid>
    )}
  </Grid>
);

export default ProposalDetail;
