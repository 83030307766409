import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Link,
  Box,
} from '@mui/material';
import { spacing } from '@mui/system';

import useAuth from '../../hooks/useAuth';

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const SignIn = () => {
  const navigate = useNavigate();
  const { signIn, setUserMe, userMe } = useAuth();
  const [userNameHasValue, setUserNameHasValue] = useState(false);
  const [passwordHasValue, setPasswordHasValue] = useState(false);

  const makeAnimationStartHandler = (e, type) => {
    const autofilled = !!e.target?.matches('*:-webkit-autofill');
    if (type === 'password' && autofilled) {
      setPasswordHasValue(autofilled);
    }

    if (type === 'email' && autofilled) {
      setUserNameHasValue(autofilled);
    }
  };

  useEffect(() => {
    if (userMe) {
      navigate(`/${userMe}`);
    }
  }, [userMe]);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const roleCode = await signIn(values.email.toLowerCase(), values.password);
          let role;
          if (roleCode === 1) {
            role = 'admin';
          } else if (roleCode === 2) {
            role = 'client';
          } else if (roleCode === 3) {
            role = 'salesperson';
          } else {
            role = 'user';
          }
          setUserMe(role);
          navigate(`/${role}`);
        } catch (error) {
          const message = error.message || 'Something went wrong';

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email || ''}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              setUserNameHasValue(e.target.value);
            }}
            inputProps={{
              onAnimationStart: (e) => makeAnimationStartHandler(e, 'email'),
            }}
            InputLabelProps={{
              shrink: userNameHasValue,
            }}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Lösenord"
            value={values.password || ''}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              setPasswordHasValue(e.target.value);
            }}
            inputProps={{
              onAnimationStart: (e) => makeAnimationStartHandler(e, 'password'),
            }}
            InputLabelProps={{
              shrink: passwordHasValue,
            }}
            my={2}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Kom ihåg mig"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ backgroundColor: '#006f97', fontSize: '16px' }}>
            Logga in
          </Button>
          {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link
              fullWidth
              href="/auth/reset"
              rel="noreferrer"
              sx={{ color: '#00B8B4', paddingTop: '10px' }}>
              Glömt lösenord
            </Link>
          </div> */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10px',
            }}>
            <Link sx={{ color: '#006f97' }} component={NavLink} to="/auth/restore-password">
              Glömt lösenord?
            </Link>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SignIn;
