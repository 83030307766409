import axios from '../utils/axios';

export const getExcelFile = async (type) => {
  try {
    return await axios.get(`/file/excel?type=${type}`, {
      responseType: 'blob',
    });
  } catch (error) {
    return console.log(error);
  }
};
