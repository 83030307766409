import { useMutation, useQuery } from '@tanstack/react-query';
import { useState, useEffect, useRef } from 'react';
import { toPng } from 'html-to-image';

import { getProposal, createAndUpdateProposalDetails } from '../../API/proposalApi';

const useProposalPage = ({ id, showAlert, blockRef }) => {
  const [proposalDetails, setProposalDetails] = useState([]);
  const [detailsToDelete, setDetailsToDelete] = useState([]);
  const [isImporting, setIsImporting] = useState(false);
  const { data: proposal, isLoading } = useQuery(['proposal', id], () => getProposal({ id }));

  const inputRef = useRef(null);

  const mutation = useMutation(
    ({ newDetails, oldDetails }) =>
      createAndUpdateProposalDetails({ id, newDetails, oldDetails, detailsToDelete }),
    {
      onSuccess: () => {
        showAlert({ text: 'Details saved and updated successfully!', typeOfAlert: 'success' });
        setDetailsToDelete([]);
      },
      onError: () => {
        showAlert({ text: 'Failed to save or update details.', typeOfAlert: 'error' });
      },
    },
  );

  useEffect(() => {
    if (proposal) {
      setProposalDetails(proposal.proposals_details);
    }
  }, [proposal]);

  const handleInputChange = (detailId, field, value) => {
    const updatedDetails = proposalDetails.map((detail) => {
      if (detail.id === detailId) {
        return { ...detail, [field]: value };
      }
      return detail;
    });
    setProposalDetails(updatedDetails);
  };

  const handleAddDetail = ({ area = '' }) => {
    const newDetail = { id: `new_${Date.now()}`, keyword: '', area };
    setProposalDetails((prevDetails) => [...prevDetails, newDetail]);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleDeleteDetail = (detailId) => {
    const updatedDetails = proposalDetails.filter((detail) => detail.id !== detailId);
    setProposalDetails(updatedDetails);
    if (typeof detailId === 'number') {
      setDetailsToDelete((prev) => [...prev, detailId]);
    }
  };

  const handleSave = () => {
    const newDetails = proposalDetails
      .filter((detail) => typeof detail.id === 'string')
      .map(({ keyword, area }) => ({ keyword, area }));
    const oldDetails = proposalDetails.filter((detail) => typeof detail.id === 'number');

    mutation.mutate({ newDetails, oldDetails });
  };

  const captureMap = () => {
    if (blockRef.current) {
      const filter = (node) => {
        const exclusionClasses = [
          'leaflet-control-attribution',
          'leaflet-control-container',
          'exclude-on-export',
        ];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
      };
      setIsImporting(true);
      setTimeout(() => {
        toPng(blockRef.current, { cacheBust: true, filter, skipFonts: true })
          .then((dataUrl) => {
            console.log(dataUrl);
            const link = document.createElement('a');
            link.download = 'my-image-name.png';
            link.href = dataUrl;
            link.click();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsImporting(false);
          });
      }, 0);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddDetail();
    }
  };

  return {
    proposal,
    isLoading,
    proposalDetails,
    handleInputChange,
    handleAddDetail,
    handleDeleteDetail,
    handleSave,
    isImporting,
    captureMap,
    handleKeyPress,
    inputRef,
  };
};

export default useProposalPage;
