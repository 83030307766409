import { Box, Card, CardContent, CardMedia, Divider, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import useAuth from '../hooks/useAuth';
import { countSiteLeads } from '../API/leadApi';

const SiteCard = ({ site, withRanking, withTotalLeads }) => {
  const { userMe } = useAuth();
  const [countAllLeads, setCountAllLeads] = useState();

  useEffect(() => {
    const countLeads = async () => {
      const count = await countSiteLeads(site.id, userMe === 'client' && site.ownerId);
      setCountAllLeads(count.data);
    };
    countLeads();
  }, []);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={3}
      style={{ borderRadius: '6px', overflow: 'hidden' }}>
      <Card
        sx={{
          borderRadius: '6px',
        }}>
        {site.screenshot?.fileName ? (
          <CardMedia
            sx={{
              width: '100%',
              height: '200px',
              backgroundPosition: 'top',
            }}
            image={`https://addictive-production-store.s3.eu-north-1.amazonaws.com/screenshot/${site.screenshot?.fileName}`}
            alt="Addictive"
          />
        ) : (
          <Box
            sx={{
              background: '#5a5a5a',
              width: '100%',
              height: '200px',
            }}
          />
        )}

        <CardContent
          sx={{
            padding: '16px',
            borderTop: '1px solid #EDEDED',
            overflow: 'hidden',
            backgroundColor: '#ffffff',
          }}>
          <Typography gutterBottom variant="h4" component="div">
            <Link
              href={`https://${site.domain}`}
              target="_blank"
              rel="noreferrer"
              sx={{ color: 'black' }}>
              {site.domain}
            </Link>
          </Typography>
          {userMe === 'admin' && (
            <Link
              href={`https://addictive.sanity.studio/desk/all;All;${site.sanityId};${site.sanityId}`}
              target="_blank"
              rel="noreferrer">
              {site.sanityId}
            </Link>
          )}
        </CardContent>
        {(withRanking || withTotalLeads) && (
          <>
            <Divider my={6} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6" sx={{ padding: '16px' }}>
                {withRanking && site.googleRank && `Google Rank: ${site.googleRank}`}
              </Typography>
              <Typography variant="h6" sx={{ padding: '16px' }}>
                {withTotalLeads && `Total leads: ${countAllLeads}`}
              </Typography>
            </div>
          </>
        )}
      </Card>
    </Grid>
  );
};

export default SiteCard;
