import { Link, TableCell, TableRow } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { formatDate } from '../../utils/timeJs';
import useAuth from '../../hooks/useAuth';

const ProposalTableRow = ({ proposal }) => {
  const { userMe } = useAuth();

  return (
    <TableRow>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{proposal.company.name}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatDate(proposal.createdAt)}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
        <Link component={NavLink} to={`/${userMe}/proposal/${proposal.id}`}>
          Open
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default ProposalTableRow;
