import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import useUserStatisticsTable from '../hooks/useUserStatisticsTable';

const UserStatisticsTable = () => {
  const {
    users,
    count,
    page,
    search,
    limit,
    handleChangePage,
    searchInputHandler,
    handleChangeLimit,
  } = useUserStatisticsTable();

  return (
    <Card>
      <TextField
        sx={{ margin: '15px  0 0 15px' }}
        id="standard-basic"
        label="Sök företag"
        variant="outlined"
        onChange={searchInputHandler}
        value={search}
        size="small"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Företag</TableCell>
            <TableCell>Visits last 30 days</TableCell>
            {/* <TableCell>Live date</TableCell> */}
            <TableCell>Senast besökt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.companyName}</TableCell>
              <TableCell>{user.count_last_30_days_login}</TableCell>
              {/* <TableCell>{user.agreementStartdate?.slice(0, 10) || ''}</TableCell> */}
              <TableCell>{user.lastLogin?.slice(0, 10) || ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {count > limit && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeLimit}
          />
        </div>
      )}
    </Card>
  );
};

export default UserStatisticsTable;
