import React from 'react';
import { Navigate } from 'react-router-dom';
import { isUserRole } from '../../utils/jwt';

// For routes that can only be accessed by authenticated users
const AdminGuard = ({ children }) => {
  const role = isUserRole();
  if (role !== 'admin') {
    return <Navigate to={`/${role}`} />;
  }

  return children;
};

export default AdminGuard;
