import { Box } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../vendor/logo_addictive_black.png';

const AddLogoToFooter = ({ children, bgColor }) => {
  const isLgUp = useMediaQuery('(min-width:1280px)');
  return (
    <Box
      sx={{
        padding: isLgUp ? 12 : 5,
        backgroundColor: bgColor ? bgColor : '#EDF7F9',
        boxSizing: 'border-box',
      }}>
      <div style={{ minHeight: 'calc(100vh - 218px)', boxSizing: 'border-box' }}>{children}</div>
      <div
        style={{
          height: '84px',
          backgroundColor: bgColor ? bgColor : '#EDF7F9',
        }}>
        <Box
          sx={{
            float: 'right',
            margin: '50px 0 0',
            width: '300px',
            '@media (max-width: 600px)': { width: '150px', margin: '30px 0' },
          }}>
          <img style={{ width: '100%' }} src={logo} alt="logo" />
        </Box>
      </div>
    </Box>
  );
};

export default AddLogoToFooter;
