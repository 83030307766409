import { createContext, useMemo, useState } from 'react';

const LeadsContext = createContext(null);

const LeadsContextProvider = ({ children }) => {
  const [leads, setLeads] = useState([]);
  const [expandedId, setExpandedId] = useState([]);
  const [isOpenLead, setIsOpenLead] = useState(null);

  const contextValue = useMemo(
    () => ({
      leads,
      expandedId,
      setExpandedId,
      setLeads,
      isOpenLead,
      setIsOpenLead,
    }),
    [leads, expandedId, isOpenLead],
  );

  return <LeadsContext.Provider value={contextValue}>{children}</LeadsContext.Provider>;
};

export { LeadsContext, LeadsContextProvider };
