import { useCallback, useState } from 'react';
import { putOptimization } from '../../API/companyApi';
import { debounce } from '../../utils/debounce';

const useOptimizationTextArea = ({ defaultValue, optimizationsId, changeComment }) => {
  const [comment, setComment] = useState(defaultValue);

  const debouncedPutOptimization = useCallback(
    debounce((value) => {
      putOptimization({ id: optimizationsId, comment: value || '' });
    }, 3000),
    [],
  );

  const handleInputChange = useCallback(
    (e) => {
      const newNotes = e.target.value;
      changeComment(optimizationsId, newNotes);
      debouncedPutOptimization(newNotes);
      setComment(newNotes);
    },
    [defaultValue],
  );
  return { comment, handleInputChange };
};

export default useOptimizationTextArea;
