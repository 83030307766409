import { decodeSpecialCharacters } from './decodeSpecialCharacters';

export const copyToClipboard = ({ content }) => {
  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = decodeSpecialCharacters(content);
  document.body.appendChild(tempContainer);
  const range = document.createRange();
  range.selectNodeContents(tempContainer);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }
  selection.removeAllRanges();
  document.body.removeChild(tempContainer);
};
