import axios from '../utils/axios';

export const getUserStatistics = async ({ userId, from, to }) => {
  let url = `/api/my-pages/statistic?from=${from}&to=${to}`;
  if (userId) {
    url += `&userId=${userId}`;
  }
  const { data } = await axios.get(url);
  return data;
};
