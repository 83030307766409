import { getExcelFile } from '../API/fileApi';
import { EXCEL_TYPE_FILENAME } from '../constants/excelTypes';

export const createExcelFile = async (type) => {
  const response = await getExcelFile(type);
  if (response) {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] }),
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = `${EXCEL_TYPE_FILENAME[`${type}`]}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } else {
    console.error('Failed to download file');
  }
};
