import { Menu, MenuItem } from '@mui/material';
import { LEADS_EXPECTATION, LEADS_EXPECTATION_NAMES } from '../constants/leadsExpectationNames';

const LeadExpectationMenu = ({ open, onClose, onChange, anchorEl }) => (
  <Menu anchorEl={anchorEl} keepMounted open={!!open} onClose={onClose}>
    <MenuItem onClick={onChange} value={LEADS_EXPECTATION.SMALL}>
      {LEADS_EXPECTATION_NAMES[LEADS_EXPECTATION.SMALL]}
    </MenuItem>
    <MenuItem onClick={onChange} value={LEADS_EXPECTATION.MEDIUM}>
      {LEADS_EXPECTATION_NAMES[LEADS_EXPECTATION.MEDIUM]}
    </MenuItem>
    <MenuItem onClick={onChange} value={LEADS_EXPECTATION.LARGE}>
      {LEADS_EXPECTATION_NAMES[LEADS_EXPECTATION.LARGE]}
    </MenuItem>
  </Menu>
);

export default LeadExpectationMenu;
