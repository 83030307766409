import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, CardHeader } from '@mui/material';

const LeadChart = ({ leadsInDay }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = leadsInDay.days;

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: leadsInDay.leadInDay,
        backgroundColor: '#4782da',
      },
    ],
  };

  return (
    <Card>
      <CardHeader title="Lead senaste dygnet" />
      <CardContent sx={{ paddingTop: 0 }}>
        <div style={{ minHeight: '200px' }}>
          <Bar options={options} data={data} />
        </div>
      </CardContent>
    </Card>
  );
};

export default LeadChart;
