export const LEAD_SOURCES = {
  CUSTOM: '1',
  NIMBATA: '2',
  FORM: '3',
};

export const LEADS_STATUS = {
  0: 'Pages with no client',
  1: 'Pages with client',
};
