import axios from '../utils/axios';

export const getProposals = async ({ page = 1, limit = 10, search, reverseSort }) => {
  try {
    const { data } = await axios.get('/api/my-pages/proposal', {
      params: {
        page,
        limit,
        search,
        reverseSort,
      },
    });
    return data;
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};

export const createProposal = async ({ companyId }) => {
  try {
    const { data } = await axios.post('/api/my-pages/proposal', { company_id: companyId });
    return data;
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};

export const getProposal = async ({ id }) => {
  try {
    const { data } = await axios.get(`api/my-pages/proposal/${id}`);
    return data.proposal;
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};

export const createProposalDetails = async ({ id, details }) => {
  try {
    const { data } = await axios.post(`api/my-pages/proposal/${id}/details`, { details });
    return data;
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};

export const updateProposalDetails = async ({ id, details }) => {
  try {
    const { data } = await axios.put(`api/my-pages/proposal/${id}/details`, { details });
    return data;
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};

export const deleteProposalDetails = async ({ id, details }) => {
  try {
    const { data } = await axios.post(`api/my-pages/proposal/${id}/delete-details`, { details });
    return data;
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};

export const createAndUpdateProposalDetails = async ({
  id,
  newDetails,
  oldDetails,
  detailsToDelete,
}) => {
  try {
    const createPromise =
      newDetails && newDetails.length > 0
        ? createProposalDetails({ id, details: newDetails })
        : Promise.resolve(null);

    const updatePromise =
      oldDetails && oldDetails.length > 0
        ? updateProposalDetails({ id, details: oldDetails })
        : Promise.resolve(null);

    const deletePromise =
      detailsToDelete && detailsToDelete.length > 0
        ? deleteProposalDetails({ id, details: detailsToDelete })
        : Promise.resolve(null);

    const [createData, updateData] = await Promise.all([
      createPromise,
      updatePromise,
      deletePromise,
    ]);

    return {
      success: true,
      message: 'Mutations completed successfully.',
      createData,
      updateData,
    };
  } catch (e) {
    return Promise.reject(e.response?.data);
  }
};
