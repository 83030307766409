import { Button, Menu, MenuItem } from '@mui/material';
import { OPTIMIZATION_STAGE } from '../../constants/optimizationsStage';
import useChangeOptimizationStageButton from '../../hooks/Optimization/useChangeOptimizationStageButton';

const ChangeOptimizationStageButton = ({
  disabled,
  stage,
  onChange,
  optimizationId,
  companyId,
}) => {
  const { open, anchorEl, onClose, changeHandler, onClickButton } =
    useChangeOptimizationStageButton({ onChange, stage, optimizationId, companyId });
  return (
    <>
      <Button
        disabled={disabled}
        onClick={onClickButton}
        sx={{ margin: '15px 0' }}
        size="small"
        variant="contained">
        Change stage
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={onClose} onChange={changeHandler}>
        <MenuItem
          sx={{
            background: stage === OPTIMIZATION_STAGE.BESTÄLLNING ? '#EDF7F9' : '',
          }}
          onClick={changeHandler}
          value={OPTIMIZATION_STAGE.BESTÄLLNING}>
          Beställning
        </MenuItem>
        <MenuItem
          sx={{
            background: stage === OPTIMIZATION_STAGE.PRODUCTION ? '#EDF7F9' : '',
          }}
          onClick={changeHandler}
          value={OPTIMIZATION_STAGE.PRODUCTION}>
          Production
        </MenuItem>
        <MenuItem
          sx={{
            background: stage === OPTIMIZATION_STAGE.LIVE ? '#EDF7F9' : '',
          }}
          onClick={changeHandler}
          value={OPTIMIZATION_STAGE.LIVE}>
          Live
        </MenuItem>
        <MenuItem
          sx={{
            background: stage === OPTIMIZATION_STAGE.LOST ? '#EDF7F9' : '',
          }}
          onClick={changeHandler}
          value={OPTIMIZATION_STAGE.LOST}>
          Lost
        </MenuItem>
      </Menu>
    </>
  );
};

export default ChangeOptimizationStageButton;
