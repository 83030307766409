import { Table, TableBody, Card, TablePagination, Box, TextField, Button } from '@mui/material';

import { useProposals } from '../../hooks/proposal/useProposals';
import { usePagination } from '../../hooks/table/usePagination';
import ProposalsTableHeader from '../../components/proposal/ProposalsList/ProposalsTableHeader';
import ProposalTableRow from './ProposalTableRow';
import { TableWrapper } from '../../components/styled/TableWrapper';
import CompanySearchModal from '../../components/proposal/CompanySearchModal';
import useCompanySearchModal from '../../hooks/proposal/useCompanySearchModal';

const ProposalsListContainer = () => {
  const { page, limit, handlePageChange, handleRowsPerPageChange } = usePagination({
    initialPage: 1,
    initialLimit: 10,
  });

  const { proposals, count, search, handleSearchChange, handleChangeSortDirection } = useProposals({
    pagination: { page, limit },
  });

  const {
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    companies,
    handleCreateProposal,
    setSearchTerm,
    searchTerm,
    setSelectedCompany,
    selectedCompany,
    isSubmitting,
  } = useCompanySearchModal();

  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          gap: '16px 0',
          flexWrap: 'wrap',
          padding: '16px',
          borderBottom: '1px solid #ccc',
          justifyContent: 'space-between',
        }}>
        <TextField
          sx={{ marginBottom: 0, marginRight: '15px' }}
          id="standard-basic"
          label="Search Company"
          variant="outlined"
          onChange={handleSearchChange}
          value={search}
          size="small"
        />
        <Button variant="contained" onClick={handleOpenModal}>
          Ny förslag
        </Button>
      </Box>

      <CompanySearchModal
        open={isModalOpen}
        onClose={handleCloseModal}
        companies={companies}
        handleCreateProposal={handleCreateProposal}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        setSelectedCompany={setSelectedCompany}
        selectedCompany={selectedCompany}
        isSubmitting={isSubmitting}
      />

      <TableWrapper>
        <Table>
          <ProposalsTableHeader onClick={handleChangeSortDirection} />
          <TableBody>
            {proposals?.map((proposal) => (
              <ProposalTableRow key={proposal.id} proposal={proposal} />
            ))}
          </TableBody>
        </Table>
        {count && (
          <TablePagination
            component="div"
            count={count || 0}
            page={page - 1}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            rowsPerPage={limit}
            onRowsPerPageChange={(event) =>
              handleRowsPerPageChange(parseInt(event.target.value, 10))
            }
            rowsPerPageOptions={[10, 25, 50]}
          />
        )}
      </TableWrapper>
    </Card>
  );
};

export default ProposalsListContainer;
