import { useEffect, useState } from 'react';
import { getAllSitesCompany } from '../API/companyApi';

const useCompanySiteTable = ({ companyName }) => {
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getSitesList = async () => {
      const res = await getAllSitesCompany(encodeURIComponent(companyName));
      setSites(res);
      setIsLoading(false);
    };
    getSitesList();
  }, []);
  return { isLoading, sites };
};

export default useCompanySiteTable;
