import dayjs from 'dayjs';

export const getLastSevenDaysRange = () => {
  const range = {};
  const today = dayjs();
  range.from = today.subtract(7, 'day').format('YYYY-MM-DD');
  range.to = today.format('YYYY-MM-DD');

  return range;
};

export const getLastThirtyDaysRange = () => {
  const range = {};
  const today = dayjs();
  range.from = today.subtract(30, 'day').format('YYYY-MM-DD');
  range.to = today.format('YYYY-MM-DD');

  return range;
};

export const dateNow = () => dayjs().format('YYYY-MM-DD HH:mm:ss');

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).format(date);
};
