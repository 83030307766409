import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Outlet, useLocation } from 'react-router-dom';

import { Box, CssBaseline, Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';

import GlobalStyle from '../components/GlobalStyle';
import Navbar from '../components/navbar/Navbar';
import dashboardItems from '../components/sidebar/dashboardItems';
import salespersonDashboardItems from '../components/sidebar/salespersonDashboardItems';
import adminDashboardItems from '../components/sidebar/adminDashboardItems';
import userDashboardItems from '../components/sidebar/userDashboardItems';

import Sidebar from '../components/sidebar/Sidebar';
import { isUserRole } from '../utils/jwt';
import { GlobalContext } from '../contexts/GlobalContext';
import { updateOptimizationMenuCounts } from '../utils/dashboard';

const drawerWidth = 175;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    font-size: 10px;

    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const router = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const role = isUserRole();
  const { optimizationListAmounts } = useContext(GlobalContext);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  const getDashboardItems = () => {
    if (role === 'admin') {
      return updateOptimizationMenuCounts(adminDashboardItems, optimizationListAmounts);
    }
    if (role === 'client') {
      return dashboardItems;
    }
    if (role === 'salesperson') {
      return salespersonDashboardItems;
    }
    return userDashboardItems;
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={getDashboardItems()}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar PaperProps={{ style: { width: drawerWidth } }} items={getDashboardItems()} />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent>
          {children}
          <Outlet />
        </MainContent>
        {/* <Footer /> */}
      </AppContent>
    </Root>
  );
};

export default Dashboard;
