import { useEffect, useRef, useState } from 'react';
import { Box, CardContent, ClickAwayListener, Typography, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LEAD_SOURCES } from '../../constants/leadConstants';
import { dateFormatter } from '../../utils/dateFormatter';
import { decodeSpecialCharacters } from '../../utils/decodeSpecialCharacters';
import useAuth from '../../hooks/useAuth';
import './style.css';
import RequestLeadsButton from '../RequestLeadButton';

const LeadContent = ({ lead, isOpen, setIsOpen }) => {
  const { userMe } = useAuth();
  const blockRef = useRef();
  const [mailSize, setMailSize] = useState({ width: '100%', height: '100%', scale: 'scale(1)' });
  const isDesktop = useMediaQuery('(min-width:600px)');
  if (userMe === 'client') {
    useEffect(() => {
      const updateWidth = () => {
        const blockWidth = blockRef.current?.getBoundingClientRect().width;
        const blockHeight = blockRef.current?.getBoundingClientRect().height;
        const screenWidth = window.innerWidth - 64;

        if (blockWidth > screenWidth) {
          const scale = screenWidth / blockWidth;
          const scaleTransform = `scale(${scale})`;

          setMailSize({ width: screenWidth, height: blockHeight * scale, scale: scaleTransform });
        }
      };

      updateWidth();
    }, []);
  }

  const parseNameAndEmail = (str) => {
    const quoteIndex = str.indexOf('<');
    if (quoteIndex !== -1) {
      const name = str.slice(0, quoteIndex).trim();
      const email = str.slice(quoteIndex).trim();
      return (
        <>
          {name}
          <span style={{ fontWeight: 400, fontSize: isDesktop ? '16px' : '14px' }}> {email}</span>
        </>
      );
    }
    return str;
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  if (lead.source === LEAD_SOURCES.CUSTOM) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          {lead.from && (
            <Typography
              sx={{
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: isDesktop ? '20px' : '16px',
              }}
              style={{ overflowWrap: 'anywhere' }}
              variant="h3">
              {parseNameAndEmail(lead.from)}
            </Typography>
          )}
          {isDesktop && userMe === 'client' && (
            <div
              onClick={(e) => {
                setIsOpen(lead.id);
                e.stopPropagation();
              }}
              style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                cursor: 'pointer',
                paddingRight: '19px',
                left: '-25px',
              }}>
              Svara på förfrågan <ExpandMoreIcon />
              {isOpen === lead.id && (
                <ClickAwayListener onClickAway={handleClose}>
                  <Box sx={{ position: 'absolute', padding: '6px 0' }}>
                    <RequestLeadsButton lead={lead} onClose={() => setIsOpen(null)} />
                  </Box>
                </ClickAwayListener>
              )}
            </div>
          )}
        </div>
        {(userMe === 'client' || !isDesktop) && lead.domain && (
          <Typography sx={{ fontSize: isDesktop ? '16px' : '14px', marginBottom: '20px' }}>
            {lead.domain}
          </Typography>
        )}
        {lead.content?.length ? (
          <div
            ref={blockRef}
            className="contentBlock"
            style={{
              overflowX: 'auto',
              marginRight: isDesktop ? '100px' : 0,
              transform: mailSize.scale,
              transformOrigin: '0 0',
              maxWidth: mailSize.width,
              maxHeight: isDesktop ? mailSize.height : '100%',
              marginBottom: '20px',
              whiteSpace: lead.content.includes(
                'Detta meddelande var skickat frÃ¥n:' || 'Polityka prywatności',
              )
                ? 'pre-wrap'
                : '',
            }}
            dangerouslySetInnerHTML={{
              __html: decodeSpecialCharacters(lead.content),
            }}
          />
        ) : (
          ''
        )}
      </>
    );
  }
  if (lead.source === LEAD_SOURCES.NIMBATA) {
    if (typeof lead.data === 'object') {
      return (
        <div style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '20px' }}>
            <Typography
              sx={{
                marginTop: '20px',
                marginBottom: '20px',
                fontSize: isDesktop ? '20px' : '16px',
              }}
              variant="h3">
              <b>Tracking Number: </b>{' '}
              <span style={{ fontWeight: 400, fontSize: isDesktop ? '16px' : '14px' }}>
                {lead.data['Tracking Number'] || lead.data['Tracking Number (international)']}
              </span>
            </Typography>
            {userMe === 'client' && (
              <span
                onClick={(e) => {
                  setIsOpen(lead.id);
                  e.stopPropagation();
                }}
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  cursor: 'pointer',
                }}>
                {isDesktop && (
                  <>
                    Svara på förfrågan
                    <ExpandMoreIcon />
                  </>
                )}
                {isOpen === lead.id && (
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box sx={{ position: 'absolute', padding: '6px 0' }}>
                      <RequestLeadsButton lead={lead} onClose={() => setIsOpen(null)} />
                    </Box>
                  </ClickAwayListener>
                )}
              </span>
            )}
          </div>
          {!lead.data['Project Name'] && !lead.data['Brand Name'] && lead.domain && (
            <Typography sx={{ fontSize: '14px' }}>
              <b>Från : </b>
              {lead.domain}
            </Typography>
          )}
          {Object.keys(lead.data).map((field) => {
            if (field !== 'Tracking Number') {
              if (field === 'Start Time (local timezone)') {
                return (
                  <Typography key={field} sx={{ fontSize: '14px' }}>
                    <b>{field}: </b>
                    {dateFormatter(lead.data[field])}
                  </Typography>
                );
              }
              return (
                <Typography key={field} sx={{ fontSize: '14px' }}>
                  <b>{field}: </b>
                  {lead.data[field]}
                </Typography>
              );
            }
            return null;
          })}
        </div>
      );
    }
    if (typeof lead.data === 'string') {
      let content = lead.data;
      const bodyStart = lead.data.indexOf('<body>');
      const bodyEnd = lead.data.indexOf('</body>');
      if (bodyStart !== -1 && bodyEnd !== -1) {
        content = lead.data.substring(bodyStart + 6, bodyEnd);
      }
      const decodedContent = decodeSpecialCharacters(content);
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: decodedContent,
          }}
        />
      );
    }
  }
  if (lead.source === LEAD_SOURCES.FORM) {
    if (typeof lead.data === 'object') {
      return (
        <CardContent sx={{ padding: 0, marginRight: '20px' }}>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isDesktop ? 'row' : 'column-reverse',
              position: 'relative',
            }}>
            <Typography>
              <b style={{ fontSize: '14px' }}>Namn : </b>
              {lead.data.Namn}
            </Typography>
            {userMe === 'client' && isDesktop && (
              <span
                onClick={(e) => {
                  setIsOpen(lead.id);
                  e.stopPropagation();
                }}
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  cursor: 'pointer',
                }}>
                Svara på förfrågan <ExpandMoreIcon />
                {isOpen === lead.id && (
                  <ClickAwayListener onClickAway={handleClose}>
                    <div style={{ position: 'absolute', padding: '6px 0' }}>
                      <RequestLeadsButton lead={lead} onClose={() => setIsOpen(null)} />
                    </div>
                  </ClickAwayListener>
                )}
              </span>
            )}
          </div>
          {Object.keys(lead.data).map((key) => {
            if (key !== 'from' && key !== 'Namn' && lead.data[key].length) {
              if (key === 'content') {
                return (
                  <Typography key={key} sx={{ marginRight: '100px' }}>
                    <b style={{ 'text-transform': 'capitalize', fontSize: '14px' }}>{key} :</b>{' '}
                    {decodeSpecialCharacters(lead.data[key])}
                  </Typography>
                );
              }
              return (
                <Typography
                  key={key}
                  sx={{ fontSize: '14px', marginRight: isDesktop ? '100px' : 0 }}>
                  <b>{key} :</b> {lead.data[key]}
                </Typography>
              );
            }
            return null;
          })}

          {(userMe === 'client' || !isDesktop) && lead.data.from && (
            <Typography sx={{ fontSize: '14px' }}>
              <b>Från : </b>
              {lead.data.from}
            </Typography>
          )}
        </CardContent>
      );
    }
    if (typeof lead.data === 'string') {
      let content = lead.data;
      const bodyStart = lead.data.indexOf('<body>');
      const bodyEnd = lead.data.indexOf('</body>');
      if (bodyStart !== -1 && bodyEnd !== -1) {
        content = lead.data.substring(bodyStart + 6, bodyEnd);
      }
      const decodedContent = decodeSpecialCharacters(content);
      return (
        <div
          style={{ fontSize: '14px' }}
          dangerouslySetInnerHTML={{
            __html: decodedContent,
          }}
        />
      );
    }
  }

  return null;
};

export default LeadContent;
