import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCompanies } from '../../API/companyApi';
import { createProposal } from '../../API/proposalApi';
import useAuth from '../useAuth';

const useCompanySearchModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { userMe } = useAuth();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const navigate = useNavigate();

  const { data: companies } = useQuery(
    ['companies', searchTerm],
    () => getCompanies({ search: searchTerm }),
    { enabled: !!searchTerm },
  );

  const mutation = useMutation((companyId) => createProposal({ companyId }), {
    onSuccess: (data) => {
      navigate(`/${userMe}/proposal/${data.proposal.id}`);
    },
  });

  const handleCreateProposal = () => {
    if (selectedCompany) {
      mutation.mutate(selectedCompany.id);
    }
  };

  return {
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    companies,
    handleCreateProposal,
    setSearchTerm,
    searchTerm,
    setSelectedCompany,
    selectedCompany,
    isSubmitting: mutation.isLoading,
  };
};

export default useCompanySearchModal;
