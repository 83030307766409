import { useMemo } from 'react';
import { Button } from '@mui/material';
import { MapContainer, GeoJSON, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import geoData from '../../swedish_municipalities.json';
import regionGeoData from '../../swedish_regions.json';
import landscapeGeoData from '../../swedish_landscape.json';
import showOnlySwedenOnMap from '../../utils/showOnlySwedenOnMap';

// Fix for Leaflet icons
// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const ProposalDetailMap = ({ areas, onMapSave, isShowImportButton, handleAddDetail }) => {
  const collectedData = useMemo(
    () => ({
      ...geoData,
      features: [...regionGeoData.features, ...landscapeGeoData.features, ...geoData.features],
    }),
    [],
  );

  const isBigMap = useMemo(() => areas?.length > 6, [areas]);

  const usedNames = new Set();

  const checkAreaMatch = (cityName, feature) => {
    const normalizedCityName = cityName.toLowerCase().trim();

    if (usedNames.has(normalizedCityName)) {
      return { isCityInAreas: false, isLanInAreas: false };
    }

    const isCityInAreas = areas.some(
      (area) => area.toLowerCase().trim() === cityName.toLowerCase() && feature.properties.id,
    );
    const isLanInAreas = areas.some(
      (area) =>
        area.toLowerCase().trim() === `${cityName} län`.toLowerCase() && !feature.properties.id,
    );

    if (isCityInAreas || isLanInAreas) {
      usedNames.add(normalizedCityName);
    }

    return { isCityInAreas, isLanInAreas };
  };

  const getStyle = (feature) => {
    const { kom_namn: cityName } = feature.properties;
    const { isCityInAreas, isLanInAreas } = checkAreaMatch(cityName, feature);
    let fillColor = 'rgba(255, 255, 255, 0.5)';
    if (isCityInAreas) {
      fillColor = '#C7468B';
    } else if (isLanInAreas) {
      fillColor = 'rgba(199, 70, 139,0.3)';
    }

    return {
      fillColor,
      weight: 1,
      opacity: isCityInAreas || isLanInAreas ? 1 : 0,
      color: '#992f68',
      fillOpacity: isCityInAreas || isLanInAreas ? 1 : 0,
    };
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.kom_namn) {
      layer.on('click', () => {
        const cityName = feature.properties.kom_namn;
        handleAddDetail({ area: cityName });
      });
    }
  };

  const renderGeoJSON = useMemo(
    () => <GeoJSON data={collectedData} style={getStyle} onEachFeature={onEachFeature} />,
    [collectedData, areas],
  );

  return (
    <div
      key={isBigMap ? 'big' : 'small'}
      style={{
        height: isBigMap ? '700px' : '500px',
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <MapContainer
        center={[63, 15]}
        zoom={isBigMap ? 5 : 4}
        style={{ height: '100%', width: '100%' }}
        keyboard={false}>
        <TileLayer
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <GeoJSON
          data={showOnlySwedenOnMap()}
          style={{
            color: '#D9D9D9',
            fillColor: '#D9D9D9',
            fillOpacity: 1,
            weight: 0,
          }}
        />
        {renderGeoJSON}
      </MapContainer>
      {isShowImportButton && (
        <Button
          variant="text"
          color="primary"
          onClick={onMapSave}
          sx={{ mt: 4, width: 150 }}
          className="exclude-on-export">
          Spara karta som bild
        </Button>
      )}
    </div>
  );
};

export default ProposalDetailMap;
