import { useSearchParams } from 'react-router-dom';

export const usePagination = ({ initialPage = 1, initialLimit = 10 }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page'), 10) || initialPage;
  const limit = parseInt(searchParams.get('limit'), 10) || initialLimit;

  const handlePageChange = (newPage) => {
    setSearchParams({ page: newPage + 1, limit });
  };

  const handleRowsPerPageChange = (newLimit) => {
    setSearchParams({ page: 1, limit: newLimit });
  };

  return {
    page,
    limit,
    handlePageChange,
    handleRowsPerPageChange,
  };
};
