const SetNewLead = ({ onClick, children }) => (
  <div
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = '#efeef1';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = 'transparent';
    }}
    onClick={onClick}
    style={{
      color: 'inherit',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      gap: '10px',
      padding: '6px 30px 6px 15px',
      backgroundColor: 'transparent',
      width: '100%',
    }}>
    {children}
  </div>
);

export default SetNewLead;
