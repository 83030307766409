import { useEffect, useState } from 'react';
import { getAllSitesCompany, putOptimization } from '../../API/companyApi';

const useOptimizationSiteTable = ({ companyName, optimizationsId, status, changeStage }) => {
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [stage, setStage] = useState(status);

  const getSitesList = async () => {
    const res = await getAllSitesCompany(encodeURIComponent(companyName));
    setSites(res);
    setLoading(false);
  };

  useEffect(() => {
    getSitesList();
  }, []);

  const saveStage = async ({ e }) => {
    try {
      setLoading(true);
      setStage(e.target.value);
      changeStage(optimizationsId, e.target.value);
      putOptimization({ id: optimizationsId, stage: e.target.value });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    loading,
    sites,
    stage,
    saveStage,
  };
};

export default useOptimizationSiteTable;
