import { createContext, useEffect, useMemo, useState, useCallback } from 'react';
import { Snackbar, Alert, Stack } from '@mui/material';
import { countOptimizationListByStage } from '../API/companyApi';
import useAuth from '../hooks/useAuth';

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [optimizationListAmounts, setOptimizationListAmounts] = useState({});
  const [openAlert, setOpenAlert] = useState(null);
  const [textAlert, setTextAlert] = useState(null);
  const { userMe } = useAuth();
  const showAlert = useCallback((alert) => {
    setTextAlert(alert);
    setOpenAlert(true);
  }, []);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const fetchOptimizationAmounts = async () => {
    try {
      const data = await countOptimizationListByStage();
      setOptimizationListAmounts(data);
    } catch (error) {
      console.error('Error fetching optimization amounts:', error);
    }
  };

  useEffect(() => {
    if (userMe) {
      fetchOptimizationAmounts();
    }
  }, [userMe]);

  const contextValue = useMemo(
    () => ({
      optimizationListAmounts,
      setOptimizationListAmounts,

      showAlert,

      fetchOptimizationAmounts,

    }),
    [optimizationListAmounts],
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          style={{ zIndex: 3000 }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlertClose}>
          <Alert
            onClose={handleAlertClose}
            severity={textAlert?.typeOfAlert}
            sx={{ width: '100%' }}>
            {textAlert?.text}
          </Alert>
        </Snackbar>
      </Stack>
      {children}
    </GlobalContext.Provider>
  );
};
