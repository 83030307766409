import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TableCell } from '@mui/material';

const SortingTableCell = ({ title, fieldName, sort, reverseSort, onClick, style }) => (
  <TableCell
    onClick={() => onClick(fieldName)}
    sx={{
      whiteSpace: 'nowrap',
      width: '300px',
      cursor: 'pointer',
      fontWeight: 400,
      padding: '8px',
      ...style,
    }}>
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      {title}
      {sort === fieldName && (
        <ArrowDropDownIcon
          style={{
            transform: !reverseSort ? 'rotate(180deg)' : '',
          }}
        />
      )}
    </div>
  </TableCell>
);

export default SortingTableCell;
