import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { getProposals } from '../../API/proposalApi';

export const useProposals = ({ pagination }) => {
  const { page, limit } = pagination;
  const [searchParams, setSearchParams] = useSearchParams();
  const [reverseSort, setReverseSort] = useState('DESC');

  const search = searchParams.get('search') || '';

  const { data, isLoading, isError } = useQuery({
    queryKey: ['proposals', page, limit, search, reverseSort],
    queryFn: () => getProposals({ page, limit, search, reverseSort }),
    keepPreviousData: true,
    staleTime: 60000,
    cacheTime: 60000,
  });

  const handleSearchChange = (event) => {
    setSearchParams({ page: 1, limit, search: event.target.value });
  };

  const handleChangeSortDirection = () => {
    setReverseSort(reverseSort === 'DESC' ? 'ASC' : 'DESC');
  };

  return {
    proposals: data?.proposals || [],
    count: data?.count || 0,
    search,
    isLoading,
    isError,
    handleSearchChange,
    handleChangeSortDirection,
  };
};
