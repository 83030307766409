import { useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMediaQuery } from '@mui/material';

import { getSitesList } from '../../API/siteApi';

const ChooseLeadsSite = ({ changeCurrentSite }) => {
  const [search, setSearch] = useState('');
  const [searchSiteList, setSearchSiteList] = useState([]);
  const isDesktop = useMediaQuery('(min-width:600px)');

  useMemo(async () => {
    const response = await getSitesList(search, 0, 50);
    setSearchSiteList(response?.sites);
  }, [search]);

  const onSetCurrentSiteHandler = (event, siteValue) => {
    changeCurrentSite({ domain: siteValue?.domain, id: siteValue?.id });
    setSearch(siteValue?.domain);
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.domain}
      options={search?.length > 2 ? searchSiteList : []}
      sx={{ width: isDesktop ? 300 : '100%', margin: '12px' }}
      size="small"
      onChange={onSetCurrentSiteHandler}
      renderInput={(params) => (
        <TextField
          value={search}
          {...params}
          onChange={(e) => setSearch(e.target.value)}
          label="Current page"
        />
      )}
    />
  );
};

export default ChooseLeadsSite;
