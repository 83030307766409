import React from 'react';
import { Modal, Box, TextField, Button, Autocomplete } from '@mui/material';

const CompanySearchModal = ({
  open,
  onClose,
  companies,
  setSelectedCompany,
  searchTerm,
  setSearchTerm,
  handleCreateProposal,
  selectedCompany,
  isSubmitting,
}) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
      <Autocomplete
        options={companies?.length ? companies : []}
        size="small"
        onChange={(e, value) => {
          setSelectedCompany(value);
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            value={searchTerm}
            {...params}
            onChange={(e) => setSearchTerm(e.target.value)}
            label="Företag"
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateProposal}
        disabled={!selectedCompany || isSubmitting}
        sx={{ mt: 2 }}>
        {isSubmitting ? 'Creating...' : 'Create Proposal'}
      </Button>
    </Box>
  </Modal>
);

export default CompanySearchModal;
