import { LEAD_SOURCES } from '../constants/leadConstants';

export const getSource = (source) => {
  let text = '';
  if (source === LEAD_SOURCES.CUSTOM) {
    text = 'Du har fått ett mail';
  } else if (source === LEAD_SOURCES.NIMBATA) {
    text = 'Du har fått ett telefonsamtal';
  } else if (source === LEAD_SOURCES.FORM) {
    text = 'Du har fått kontaktförfrågan';
  }
  return text;
};
