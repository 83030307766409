import { TableCell, TableHead, TableRow } from '@mui/material';

const ProposalsTableHeader = ({ onClick }) => (
  <TableHead>
    <TableRow>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>Proposal</TableCell>
      <TableCell onClick={onClick} sx={{ whiteSpace: 'nowrap', width: '150px', cursor: 'pointer' }}>
        Creation date
      </TableCell>
      <TableCell sx={{ width: '150px' }} align="center">
        Actions
      </TableCell>
    </TableRow>
  </TableHead>
);

export default ProposalsTableHeader;
