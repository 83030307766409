import { Button, Divider, Paper, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { updateUserPassword } from '../../API/usersApi';
import { isUser } from '../../utils/jwt';
import { ACCESS_TOKEN_USER } from '../../const';

const UserSettingsPage = () => {
  const isUserView = useMemo(
    () => window.localStorage.getItem(ACCESS_TOKEN_USER),
    [window.localStorage.getItem(ACCESS_TOKEN_USER)],
  );
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const { id } = isUser();

  const [errorMessage, setErrorMessage] = useState('');
  const [done, setDone] = useState(false);

  const onSubmit = async () => {
    if (isUserView) {
      setErrorMessage('Permission denied');
      return;
    }
    if (formData.newPassword === formData.confirmNewPassword) {
      if (formData.newPassword.trim() === '' || formData.confirmNewPassword.trim() === '') {
        setErrorMessage('All fields must be completed');
        return;
      }
      try {
        await updateUserPassword({
          userId: id,

          newPassword: formData.newPassword,
        });

        setErrorMessage('');
        setFormData({
          newPassword: '',
          confirmNewPassword: '',
        });
        setDone(true);
        setTimeout(() => {
          setDone(false);
        }, 5000);
      } catch (err) {
        setErrorMessage(err.message);
      }
    } else {
      setErrorMessage('Passwords do not match');
    }
  };
  return (
    <>
      <Typography
        sx={{
          fontWeight: 500,
          '@media (max-width: 600px)': {
            fontSize: '30px',
          },
        }}
        variant="h1">
        Inställningar
      </Typography>

      <Divider sx={{ margin: '24px 0' }} />

      <Paper sx={{ padding: '15px', maxWidth: '400px' }}>
        <h3>Byt lösenord</h3>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
          }}>
          <TextField
            label="Nytt lösenord"
            fullWidth
            type="password"
            size="small"
            variant="outlined"
            value={formData.newPassword}
            onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
          />
          <TextField
            label="Bekräfta Nytt lösenord"
            size="small"
            fullWidth
            type="password"
            variant="outlined"
            value={formData.confirmNewPassword}
            onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
          />
          <div style={{ height: '20px', textAlign: 'center' }}>
            {errorMessage && (
              <h4
                style={{
                  color: 'red',
                  margin: 0,
                }}>
                {errorMessage}
              </h4>
            )}
            {done && <h4 style={{ color: 'green', margin: 0 }}>Password changed!</h4>}
          </div>
          <Button disabled={done} type="submit" variant="contained" color="primary">
            Spara ändringar
          </Button>
        </form>
      </Paper>
    </>
  );
};

export default UserSettingsPage;
