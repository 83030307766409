import styled from '@emotion/styled';
import { TableCell as MuiTableCell } from '@mui/material';

const TableCell = styled(MuiTableCell)`
  padding: 6px;
`;

const CompanyTableCell = ({ children, sx }) => <TableCell sx={sx}>{children}</TableCell>;

export default CompanyTableCell;
