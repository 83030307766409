import { Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ProposalPageButtons = ({ onAddDetail, onSave, isShow }) => {
  if (!isShow) {
    return null;
  }
  return (
    <Grid sx={{ px: 5 }} className="exclude-on-export">
      <Grid xs={12} sx={{ mb: 5 }}>
        <Button variant="text" onClick={onAddDetail}>
          <AddIcon color="primary" />
        </Button>
      </Grid>
      <Grid xs={12}>
        <Button variant="outlined" color="primary" onClick={onSave} sx={{ mx: 3 }}>
          Spara
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProposalPageButtons;
