import React, { useState } from 'react';
import {
  FormControl as MuiFormControl,
  TextField,
  Button,
  Typography,
  Box,
  Link,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import { Formik, Form, Field } from 'formik';
import { requestRestoreUserPassword } from '../../API/usersApi';

const FormControl = styled(MuiFormControl)(spacing);

const RestorePasswordRequest = () => {
  const [requested, setRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  });

  const handleSubmit = async (values, { setErrors }) => {
    try {
      setIsLoading(true);
      await requestRestoreUserPassword(values.email);
      setRequested(true);
    } catch (err) {
      const message = err.message || 'Something went wrong';
      setErrors({ email: message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormControl
      sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between' }}>
      {!requested ? (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ isSubmitting, errors, touched }) => (
            <Form style={{ width: '100%' }}>
              <Field
                name="email"
                as={TextField}
                sx={{ marginBottom: '15px' }}
                label="Email Address"
                fullWidth
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ backgroundColor: '#006f97', fontSize: '16px' }}
                disabled={isLoading || isSubmitting}>
                Till återställning
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography variant="h4">Message sent to your email</Typography>
          <Typography variant="h7">Please, check it</Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '10px',
        }}>
        <Link sx={{ color: '#006f97' }} component={NavLink} to="/auth/sign-in">
          Tillbaka
        </Link>
      </Box>
    </FormControl>
  );
};

export default RestorePasswordRequest;
