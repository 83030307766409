import axios from 'axios';

const host = process.env.REACT_APP_BACKEND_URL;
console.log(host);

const axiosInstance = axios.create({
  baseURL: host,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response) || error),
);

export default axiosInstance;
