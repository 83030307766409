const updateOptimizationMenuCounts = (menu, counts) => {
  const menuItem = menu[0].pages.find((i) => i.href === 'optimering');
  menuItem.children.forEach((value, i) => {
    // eslint-disable-next-line
    value.title = `${value.title.split('(')[0]}(${counts[i] || 0})`;
  });
  return menu;
};

module.exports = {
  updateOptimizationMenuCounts,
};
