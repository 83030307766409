import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';

import { Paper } from '@mui/material';

import ConfirmInviteForm from '../../components/auth/ConfirmInvite';
import logo from '../../vendor/login_logo.png';
import logoBottom from '../../vendor/logo_addictive_black.png';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};token, option
  }
`;

const ConfirmInvite = () => (
  <div style={{ height: '100vh', width: '100%', paddingTop: '10vh' }}>
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <img
        style={{ maxWidth: '80px', width: '100%', paddingBottom: '20px' }}
        alt="logo"
        src={logo}
      />
      <img
        style={{
          position: 'fixed',
          bottom: '50px',
          right: '5%',
          maxWidth: '300px',
          maxHeight: '50px',
        }}
        alt="logo"
        src={logoBottom}
      />
      <h2 style={{ padding: '0 10px', fontSize: '27px', margin: '15px 0' }}>Logga in Mina sidor</h2>
    </div>
    <Wrapper sx={{ alignSelf: 'center', marginTop: '20px' }}>
      <Helmet title="Logga in Mina sidor" />
      <ConfirmInviteForm />
    </Wrapper>
  </div>
);

export default ConfirmInvite;
