import styled from '@emotion/styled';
import {
  Box,
  Table,
  TableBody,
  TableCell as ProposalTableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const TableCell = styled(ProposalTableCell)`
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  border: none;
  padding: 16px;
  margin: 6px 0 0 0;
  text-align: center;
`;

const ProposalTable = ({ details }) => (
  <Box>
    <Table>
      <TableHead>
        <TableRow sx={{ background: '#e6ebed' }}>
          <TableCell sx={{ fontWeight: 700 }}>Keyword</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Area</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {details.map((detail) => (
          <TableRow key={detail.id}>
            <TableCell sx={{ fontWeight: 500 }}>{detail.keyword}</TableCell>
            <TableCell sx={{ fontWeight: 500 }}>{detail.area}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
);

export default ProposalTable;
