import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import { Box } from '@mui/material';
import { decodeSpecialCharacters } from '../utils/decodeSpecialCharacters';
import RequestLink from './RequestLink';
import { extractEmail } from '../utils/extractEmail';
import SetNewLead from './SetNewLead';
import useAuth from '../hooks/useAuth';
import { ACCESS_TOKEN_USER } from '../const';
import { setLeadsNotNew } from '../API/leadApi';
import { LeadsContext } from '../contexts/LeadsContext';

const RequestLeadsButton = ({ lead, onClose }) => {
  const navigate = useNavigate();
  const { userMe } = useAuth();
  const isUserView = useMemo(
    () => window.localStorage.getItem(ACCESS_TOKEN_USER),
    [window.localStorage.getItem(ACCESS_TOKEN_USER)],
  );

  const leadsContext = useContext(LeadsContext);
  const leads = leadsContext?.leads;
  const setLeads = leadsContext?.setLeads;
  const setExpandedId = leadsContext?.setExpandedId;
  const setIsOpenLead = leadsContext?.setIsOpenLead;

  const body = useMemo(() => {
    if (lead.source === '1') {
      encodeURIComponent(
        decodeSpecialCharacters(lead.content)
          .replace(/%0A/g, '\n')
          .replace(/<br>/g, '\n')
          .replace(/<[^>]*>/g, ''),
      );
    }
    if (lead.source === '3') {
      let htmlString = '';
      if (lead.data) {
        Object.keys(lead.data).forEach((key) => {
          if (
            key !== 'from' &&
            key !== 'Namn' &&
            key !== 'E-post' &&
            key !== 'Mejl' &&
            key !== 'Telefonnummer' &&
            key !== 'Telefon' &&
            lead.data[key].length
          ) {
            if (key === 'content') {
              htmlString += `${key} : ${decodeSpecialCharacters(lead.data[key])}\n`;
            } else {
              htmlString += `${key} :${lead.data[key]}\n`;
            }
          }
        });
      }
      return htmlString;
    }
    return '';
  }, [lead.content]);

  const setNewHandler = async () => {
    await setLeadsNotNew([lead.id], true);
    if (leadsContext) {
      const newLeads = [...leads];
      const leadToUpdate = newLeads.find((leadMessage) => leadMessage.id === lead.id);
      if (leadToUpdate) {
        leadToUpdate.isNew = true;
        setLeads(newLeads);
        setExpandedId((prev) => prev.filter((item) => item !== lead.id));
        setIsOpenLead(null);
      }
    }

    navigate('/client/leads');
  };

  if (lead.source === '1' && lead.from) {
    return (
      <Box
        style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        sx={{
          position: 'absolute',
          padding: '6px 0px',
          background: 'white',
          borderRadius: '5px',
          zIndex: 2,
          right: '-150px',
          top: '-100%',
        }}>
        <RequestLink
          onClose={() => onClose()}
          type="email"
          content={lead.content}
          link={`${extractEmail(lead.from)}?body=${body}`}>
          <EmailOutlinedIcon fontSize="inherit" />{' '}
          <span style={{ fontSize: '14px', whiteSpace: 'nowrap' }}> Svara via email</span>
        </RequestLink>
        {userMe === 'client' && !isUserView && (
          <SetNewLead onClick={setNewHandler}>
            <MarkEmailUnreadOutlinedIcon fontSize="inherit" />{' '}
            <span style={{ fontSize: '13px', whiteSpace: 'nowrap' }}>Markera som oläst</span>
          </SetNewLead>
        )}
      </Box>
    );
  }
  if (
    lead.source === '2' &&
    (lead.data['Tracking Number'] ||
      lead.data['Caller ID'] ||
      lead.data['Tracking Number (international)'] ||
      lead.data['Caller ID  (international)'])
  ) {
    return (
      <Box
        style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        sx={{
          position: 'absolute',
          padding: '6px 0px',
          background: 'white',
          borderRadius: '5px',
          zIndex: 2,
          right: '-150px',
          top: '-100%',
        }}>
        <RequestLink
          onClose={() => onClose()}
          type="call"
          link={`${
            lead.data['Tracking Number'] ||
            lead.data['Tracking Number (international)'] ||
            lead.data['Caller ID'] ||
            lead.data['Caller ID  (international)']
          }`}>
          <PhoneOutlinedIcon fontSize="inherit" />{' '}
          <span style={{ fontSize: '13px', whiteSpace: 'nowrap' }}> Svara genom att ringa</span>
        </RequestLink>
        {userMe === 'client' && !isUserView && (
          <SetNewLead onClick={setNewHandler}>
            <MarkEmailUnreadOutlinedIcon fontSize="inherit" />{' '}
            <span style={{ fontSize: '13px', whiteSpace: 'nowrap' }}>Markera som oläst</span>
          </SetNewLead>
        )}
      </Box>
    );
  }

  if (lead.source === '3' && (lead.data['E-post'] || lead.data.Mejl)) {
    return (
      <Box
        style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        sx={{
          position: 'absolute',
          padding: '6px 0px',
          background: 'white',
          borderRadius: '5px',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          right: '-150px',
          top: '-100%',
        }}>
        <RequestLink
          onClose={() => onClose()}
          type="email"
          content={body}
          link={lead.data['E-post'] || lead.data.Mejl}>
          <EmailOutlinedIcon fontSize="inherit" />{' '}
          <span style={{ fontSize: '13px', whiteSpace: 'nowrap' }}> Svara via email</span>
        </RequestLink>
        {(lead.data.Telefonnummer || lead.data.Telefon) && (
          <RequestLink
            onClose={() => onClose()}
            type="call"
            link={`${lead.data.Telefonnummer || lead.data.Telefon}`}>
            <PhoneOutlinedIcon fontSize="inherit" />{' '}
            <span style={{ fontSize: '13px', whiteSpace: 'nowrap' }}> Svara genom att ringa</span>
          </RequestLink>
        )}
        {userMe === 'client' && !isUserView && (
          <SetNewLead onClick={setNewHandler}>
            <MarkEmailUnreadOutlinedIcon fontSize="inherit" />{' '}
            <span style={{ fontSize: '13px', whiteSpace: 'nowrap' }}>Markera som oläst</span>
          </SetNewLead>
        )}
      </Box>
    );
  }
  return null;
};

export default RequestLeadsButton;
