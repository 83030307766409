import { TextField } from '@mui/material';

const NewOptimizationComment = ({ newComment, setNewComment }) => (
  <TextField
    multiline
    sx={{
      width: '100%',
      '& .MuiInputBase-multiline': {
        padding: '8px',
      },
      '& .MuiInputBase-input': {
        fontSize: '12px',
      },
    }}
    minRows={3}
    maxRows={8}
    size="sm"
    variant="outlined"
    value={newComment}
    name="notes"
    onChange={(e) => setNewComment(e.target.value)}
  />
);

export default NewOptimizationComment;
