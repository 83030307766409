import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { updateUserPassword } from '../../API/usersApi';

const ChangeUserPassword = ({ id, onClose, title }) => {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async () => {
    if (formData.newPassword === formData.confirmNewPassword) {
      if (formData.newPassword.trim() === '' || formData.confirmNewPassword.trim() === '') {
        setErrorMessage('All fields must be completed');
        return;
      }
      try {
        await updateUserPassword({
          userId: id,
          newPassword: formData.newPassword,
        });

        setErrorMessage('');
        onClose();
      } catch (err) {
        setErrorMessage(err.message);
      }
    } else {
      setErrorMessage('Passwords do not match');
    }
  };
  return (
    <>
      {!title && <h3>Byt lösenord</h3>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '10px',
        }}>
        <TextField
          label="Nytt lösenord"
          sx={{ width: 250 }}
          type="password"
          size="small"
          variant="outlined"
          value={formData.newPassword}
          onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
        />
        <TextField
          label="Bekräfta Nytt lösenord"
          size="small"
          sx={{ width: 250 }}
          type="password"
          variant="outlined"
          value={formData.confirmNewPassword}
          onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
        />
        {errorMessage && (
          <div style={{ height: '20px', textAlign: 'center' }}>
            <h4
              style={{
                color: 'red',
                margin: 0,
              }}>
              {errorMessage}
            </h4>
          </div>
        )}
        <Button
          type="submit"
          sx={{ width: 120, whiteSpace: 'nowrap' }}
          variant="outlined"
          color="primary">
          Spara ändringar
        </Button>
      </form>
    </>
  );
};

export default ChangeUserPassword;
