import { TableCell as MuiTableCell, TableRow } from '@mui/material';
import styled from '@emotion/styled';

const TableCell = styled(MuiTableCell)`
  padding: 8px;
  whitespace: nowrap;
  text-align: end;
`;

const CompanyTableRowWithOptimization = ({ data, getSalespersonNamesString, isDesktop }) => (
  <TableRow>
    <TableCell
      sx={{ position: isDesktop ? 'sticky' : 'static', left: 0, background: 'white', zIndex: 1 }}
    />
    <TableCell>{data.agreement_start_date?.slice(0, 10) || ''}</TableCell>
    <TableCell>{data.notice_date?.slice(0, 10) || ''}</TableCell>
    <TableCell>{data.agreement_end_date?.slice(0, 10) || ''}</TableCell>
    <TableCell>{data.renewal_date?.slice(0, 10) || ''}</TableCell>
    <TableCell>{data.revenue?.toFixed(0) || ''}</TableCell>
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell />
    <TableCell>
      {data.salesperson_id ? getSalespersonNamesString(data.salesperson_id) : ''}
    </TableCell>
    <TableCell />
  </TableRow>
);

export default CompanyTableRowWithOptimization;
