export const decodeSpecialCharacters = (content) => {
  let decodedString;

  try {
    decodedString = decodeURIComponent(escape(content));
  } catch (error) {
    decodedString = content;
  }
  const normalizedString = decodedString
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&copy;', '©')
    .replaceAll('&gt;', '>')
    .replaceAll('&lt;', '<')
    .replaceAll('Ã¥', 'å')
    .replaceAll('Ã¤', 'ä')
    .replaceAll('Ã¶', 'ö')
    .replaceAll('Â', '');

  return normalizedString;
};
